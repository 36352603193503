import React, { useState, useEffect, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import api from './axiosConfig';
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import "./Player.css";

interface TimerData {
  name: string;
  intervals: { duration: number; intervalType: string; exercise_number?: number }[];
}

interface TimerState {
  intervalIndex: number;
  timeLeft: number;
  isRunning: boolean;
}

function Player() {
  const [searchParams] = useSearchParams();
  const timerId = searchParams.get("timerId");
  const [timerData, setTimerData] = useState<TimerData | null>(null);
  const [timerState, setTimerState] = useState<TimerState>({
    intervalIndex: 0,
    timeLeft: 0,
    isRunning: false,
  });
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [showReplay, setShowReplay] = useState<boolean>(false);
  const timerCircleContainerRef = useRef<HTMLDivElement | null>(null);
  const circleRadius = 500; // Radius for 400px diameter
  const circleCircumference = 2 * Math.PI * circleRadius;

  const strokeDashArray =
    timerData?.intervals && timerState.timeLeft !== null
      ? `${
          ((timerData.intervals[timerState.intervalIndex].duration -
            timerState.timeLeft) /
            timerData.intervals[timerState.intervalIndex].duration) *
          circleCircumference
        } ${circleCircumference}`
      : `0 ${circleCircumference}`;

  useEffect(() => {
    const fetchTimerData = async () => {
      setIsLoading(true);
      try {
        const response = await api.get<TimerData>(`/timers/${timerId}`);
        setTimerData(response.data);
        setTimerState((prevState) => ({
          ...prevState,
          timeLeft: response.data.intervals[0].duration,
        }));
      } catch (error) {
        console.error("Error fetching timer data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    if (timerId) {
      fetchTimerData();
    }
  }, [timerId]);

  useEffect(() => {
    let intervalId: NodeJS.Timeout | null = null;
    if (timerState.isRunning) {
      intervalId = setInterval(() => {
        setTimerState((prevState) => {
          if (prevState.timeLeft > 1) {
            return { ...prevState, timeLeft: prevState.timeLeft - 1 };
          } else {
            if (prevState.intervalIndex < (timerData?.intervals.length || 0) - 1) {
              const newIntervalIndex = prevState.intervalIndex + 1;
              const newTimeLeft =
                timerData?.intervals[newIntervalIndex].duration || 0;
              return {
                ...prevState,
                intervalIndex: newIntervalIndex,
                timeLeft: newTimeLeft,
              };
            } else {
              clearInterval(intervalId as NodeJS.Timeout);
              setShowReplay(true);
              return { ...prevState, isRunning: false };
            }
          }
        });
      }, 1000);
    } else if (!timerState.isRunning && intervalId) {
      clearInterval(intervalId);
      intervalId = null; // Reset intervalId after clearing
    }
    return () => clearInterval(intervalId as NodeJS.Timeout);
  }, [timerState.isRunning, timerData]);

  const handleStartPause = () => {
    setTimerState((prevState) => ({
      ...prevState,
      isRunning: !prevState.isRunning,
    }));
  };

  const handleReplay = () => {
    setTimerState({
      intervalIndex: 0,
      timeLeft: timerData?.intervals[0].duration || 0,
      isRunning: true,
    });
    setShowReplay(false);
  };

  const handleSkipInterval = () => {
    if (
      timerData &&
      timerState.intervalIndex < timerData.intervals.length - 1
    ) {
      const newIntervalIndex = timerState.intervalIndex + 1;
      const newTimeLeft = timerData.intervals[newIntervalIndex].duration;
      setTimerState({
        ...timerState,
        intervalIndex: newIntervalIndex,
        timeLeft: newTimeLeft,
      });
    }
  };

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${String(minutes).padStart(2, "0")}:${String(
      remainingSeconds
    ).padStart(2, "0")}`;
  };

  const getGradientIdForInterval = (intervalType: string) => {
    if (intervalType.includes("main") || intervalType.includes("challenge")) {
      return "exerciseGradient";
    } else if (
      intervalType.includes("break") ||
      intervalType.includes("switch") ||
      intervalType.includes("pause") ||
      intervalType.includes("stretching") ||
      intervalType.includes("breathing") ||
      intervalType.includes("station") ||
      intervalType.includes("celebration")
    ) {
      return "breakGradient";
    } else if (intervalType.includes("warmup")) {
      return "warmupGradient";
    } else {
      return "defaultGradient";
    }
  };

  const currentIntervalType =
    timerData?.intervals?.[timerState.intervalIndex]?.intervalType || "";
  
    const gradientId = getGradientIdForInterval(currentIntervalType);

  const currentInterval = timerData?.intervals?.[timerState.intervalIndex];
  const intervalType = currentInterval?.intervalType
    ? currentInterval.intervalType
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ")
    : "";

  return (
    <div className="player-container">
      {isLoading ? (
        <p>Loading timer...</p>
      ) : (
        <>
          <div className="player-headline">
            <p>{timerData?.name || "Timer"}</p>
          </div>
          <div className="player-centered-content">
            <button className="player-skip-button" onClick={handleSkipInterval}>
              Skip
            </button>
            <div className="player-timer-element">
              <div className="player-coach-timer-description-container">
                {currentInterval?.exercise_number && (
                  <div className="player-exercise-number">
                    Exercise {currentInterval.exercise_number}
                  </div>
                )}
                <div className="player-interval-type">{intervalType}</div>
              </div>
              <div
                className="player-coach-timer-circle"
                ref={timerCircleContainerRef}
              >
                <svg
                  width={circleRadius * 2}
                  height={circleRadius * 2}
                  viewBox={`0 0 ${circleRadius * 2} ${circleRadius * 2}`}
                >
                  <defs>
                    <linearGradient
                      id="exerciseGradient"
                      x1="0%"
                      y1="0%"
                      x2="100%"
                      y2="100%"
                    >
                      <stop
                        offset="0%"
                        style={{ stopColor: "#FF6359", stopOpacity: 1 }}
                      />
                      <stop
                        offset="100%"
                        style={{ stopColor: "#FFC2BE", stopOpacity: 1 }}
                      />
                    </linearGradient>
                    <linearGradient
                      id="breakGradient"
                      x1="0%"
                      y1="0%"
                      x2="100%"
                      y2="100%"
                    >
                      <stop
                        offset="0%"
                        style={{ stopColor: "#599BFF", stopOpacity: 1 }}
                      />
                      <stop
                        offset="100%"
                        style={{ stopColor: "#BED8FF", stopOpacity: 1 }}
                      />
                    </linearGradient>
                    <linearGradient
                      id="warmupGradient"
                      x1="0%"
                      y1="0%"
                      x2="100%"
                      y2="100%"
                    >
                      <stop
                        offset="0%"
                        style={{ stopColor: "#FFE459", stopOpacity: 1 }}
                      />
                      <stop
                        offset="100%"
                        style={{ stopColor: "#FFF5BE", stopOpacity: 1 }}
                      />
                    </linearGradient>
                    <linearGradient
                      id="defaultGradient"
                      x1="0%"
                      y1="0%"
                      x2="100%"
                      y2="100%"
                    >
                      <stop
                        offset="0%"
                        style={{ stopColor: "#6E6E6E", stopOpacity: 1 }}
                      />
                      <stop
                        offset="100%"
                        style={{ stopColor: "#BABABA", stopOpacity: 1 }}
                      />
                    </linearGradient>
                    <filter
                        id="innerShadow"
                        x="-50%"
                        y="-50%"
                        width="200%"
                        height="200%"
                      >
                        <feComponentTransfer in="SourceAlpha">
                          <feFuncA type="table" tableValues="1 0" />
                        </feComponentTransfer>
                        <feGaussianBlur stdDeviation="14" />
                        <feOffset dx="-20" dy="0" result="offsetblur" />
                        <feFlood floodColor="#828282" result="color" />
                        <feComposite in2="offsetblur" operator="in" />
                        <feComposite in2="SourceAlpha" operator="in" />
                        <feMerge>
                          <feMergeNode in="SourceGraphic" />
                          <feMergeNode />
                        </feMerge>
                      </filter>
                  </defs>
                  <circle
                    className="player-coach-timer-circle-progress"
                    cx={circleRadius - 736}
                    cy={circleRadius}
                    r={circleRadius - 46}
                    fill="none"
                    stroke={`url(#${gradientId})`}
                    strokeWidth="64"
                    strokeLinecap="butt"
                    transform="rotate(-90 125 125)"
                    style={{
                      strokeDasharray: strokeDashArray,
                      filter: "url(#innerShadow)",
                    }}
                  />
                </svg>
                <div className="player-coach-timer-text">
                  {formatTime(timerState.timeLeft)}
                </div>
              </div>
            </div>

            <button className="player-start-button" onClick={handleStartPause}>
              {timerState.isRunning ? (
                <>
                  <PauseIcon
                    style={{
                      color: "#ffffff",
                      width: "41.14px",
                      height: "41.14px",
                    }}
                  />
                  <span>Pause</span>
                </>
              ) : (
                <>
                  <PlayArrowIcon
                    style={{
                      color: "#ffffff",
                      width: "41.14px",
                      height: "41.14px",
                    }}
                  />
                  <span>Start</span>
                </>
              )}
            </button>
          </div>
          {showReplay && (
            <button className="player-replay-button" onClick={handleReplay}>
              Replay
            </button>
          )}
        </>
      )}
    </div>
  );
}

export default Player;