import React, { useState, useEffect, ChangeEvent } from "react";
import api from './axiosConfig';
import "./AdminList.css";
import { useNavigate } from "react-router-dom";
import { FaPencilAlt, FaCheck, FaTimes, FaTrash, FaTrashRestore} from "react-icons/fa";
import { Dropdown, DropdownProps } from "semantic-ui-react";
import Layout from "./Layout";
import { CSVLink } from "react-csv";

// Define the Exercise interface
interface Exercise {
  id: string;
  name: string;
  short_name?: string;
  video_url?: string;
  exercise_group?: string;
  type?: string;
  body_part?: string;
  muscle_group: string | "undefined";
  main_muscle?: string;
  equipment?: string;
  supporting_equipment?: string;
  default_weight_1rm?: number;
  default_weight_4rm?: number;
  default_weight_6rm?: number;
  default_weight_8rm?: number;
  default_weight_12rm?: number;
  legs?: number;
  adductor?: number;
  abductor?: number;
  hamstrings?: number;
  glute_max?: number;
  glute_med?: number;
  quads?: number;
  hip_flexors?: number;
  calves?: number;
  forearms?: number;
  biceps?: number;
  triceps?: number;
  upper_back?: number;
  mid_back?: number;
  lower_back?: number;
  traps?: number;
  erector_spinae?: number;
  lats?: number;
  chest?: number;
  shoulders?: number;
  core?: number;
  transverse_abs?: number;
  rectus_abs?: number;
  obliques?: number;
  complexity?: number;
  tip_1?: string;
  tip_2?: string;
  tip_3?: string;
  archived: boolean;
  [key: string]: any; // Allow additional fields
}

function ExercisePage() {
  const [exercises, setExercises] = useState<Exercise[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [editingExerciseId, setEditingExerciseId] = useState<string | null>(null);
  const [editedExercise, setEditedExercise] = useState<Partial<Exercise>>({});
  const navigate = useNavigate();

  // Define allowed values directly in the component
  const allowedExerciseTypes = [
    "Strength",
    "Cardio",
    "Warm Up",
    "Stretching",
    "Breathing",
    "Challenge"
  ];
  const allowedBodyParts = ["Upper Body", "Lower Body", "Core", "Full Body"];
  const allowedEquipment = [
    "Cable",
    "Barbell",
    "Dumbbell",
    "Leg Press",
    "Smith Machine",
    "None",
  ];
  const allowedSupportingEquipment = ["Mat", "Stepper", "Bench", "None"];
  const allowedMuscleGroups = [
    "Chest",
    "Upper Back",
    "Lower Back",
    "Core",
    "Legs",
    "Shoulders",
  ];
  const allowedMainMuscles = [
    "None",
    "Biceps",
    "Triceps",
    "Upper Back",
    "Lower Back",
    "Chest",
    "Core",
    "Legs",
    "Glute Max",
    "Shoulders",
    "Multiple",
    "Hip Flexors",
    "Quads",
    "Glute Med",
    "Mid Back",
    "Forearms",
    "Calves",
    "Rectus Abs",
    "Transverse Abs",
    "Obliques",
    "Lats",
    "Traps",
    "Erector Spinae",
    "Adductor",
    "Abductor",
    "Hamstrings",
  ];

  const intensityFields = [
    "legs",
    "adductor",
    "abductor",
    "hamstrings",
    "glute_max",
    "glute_med",
    "quads",
    "hip_flexors",
    "calves",
    "forearms",
    "biceps",
    "triceps",
    "upper_back",
    "mid_back",
    "lower_back",
    "traps",
    "erector_spinae",
    "lats",
    "chest",
    "shoulders",
    "core",
    "transverse_abs",
    "rectus_abs",
    "obliques",
    "complexity",
  ];

  const columnOrder = [
    "name",
    "short_name",
    "video_url",
    "exercise_group",
    "type",
    "body_part",
    "muscle_group",
    "main_muscle",
    "equipment",
    "supporting_equipment",
    "tip_1",
    "tip_2",
    "tip_3",
    "default_weight_1rm",
    "default_weight_4rm",
    "default_weight_6rm",
    "default_weight_8rm",
    "default_weight_12rm",
    "legs",
    "adductor",
    "abductor",
    "hamstrings",
    "glute_max",
    "glute_med",
    "quads",
    "hip_flexors",
    "calves",
    "forearms",
    "biceps",
    "triceps",
    "upper_back",
    "mid_back",
    "lower_back",
    "traps",
    "lats",
    "chest",
    "shoulders",
    "core",
    "transverse_abs",
    "rectus_abs",
    "obliques",
    "complexity",
  ];

  const defaultWeightFields = [
    "default_weight_1rm",
    "default_weight_4rm",
    "default_weight_6rm",
    "default_weight_8rm",
    "default_weight_12rm",
  ];

  useEffect(() => {
    const fetchExercises = async () => {
      setIsLoading(true);
      try {
        const response = await api.get<Exercise[]>("/exercises");
        const sortedExercises = response.data.sort((a, b) => {
          // First sort by archived status
          if (a.archived !== b.archived) {
            return a.archived ? 1 : -1;
          }
          // Then sort by muscle_group
          if (a.muscle_group !== b.muscle_group) {
            return a.muscle_group < b.muscle_group ? -1 : 1;
          }
          // Finally sort by name
          return a.name < b.name ? -1 : 1;
        });
        setExercises(sortedExercises);
      } catch (error) {
        console.error("Error fetching exercises:", error);
        setError("Failed to fetch exercises");
      } finally {
        setIsLoading(false);
      }
    };

    fetchExercises();
  }, []);

  const handleDownloadExercises = () => {
    const csvHeaders = [
      { label: "id", key: "id" },
      ...columnOrder.map((field) => ({
        label: field,
        key: field,
      })),
    ];

    const csvData = exercises.map((exercise) => {
      const row: { [key: string]: any } = { id: exercise.id };
      columnOrder.forEach((field) => {
        row[field] = exercise[field];
      });
      return row;
    });

    return {
      headers: csvHeaders,
      data: csvData,
      filename: "exercises.csv",
    };
  };

  const handleDelete = async (id: string) => {
    try {
      await api.delete(`/exercises/${id}`);
      setExercises((prevExercises) =>
        prevExercises.filter((exercise) => exercise.id !== id)
      );
    } catch (err) {
      console.error("Error deleting exercise:", err);
      setError("Failed to delete exercise");
    }
  };

  const handleEditExercise = (exercise: Exercise) => {
    setEditingExerciseId(exercise.id);
    setEditedExercise(exercise);
  };

  const handleExerciseInputChange = (
    event: ChangeEvent<HTMLInputElement>,
    field: string
  ) => {
    const { value } = event.target;
    setEditedExercise((prevExercise) => ({
      ...prevExercise,
      [field]: value,
    }));
  };

  const handleSaveExercise = async (id: string) => {
    if (
      JSON.stringify(editedExercise) ===
      JSON.stringify(exercises.find((ex) => ex.id === id))
    ) {
      handleCancelEdit();
      return;
    }
    try {
      const updatedData = { ...editedExercise };
      defaultWeightFields.forEach((field) => {
        updatedData[field] = parseFloat(updatedData[field]) || 0;
      });
      intensityFields.forEach((field) => {
        updatedData[field] = parseInt(updatedData[field]) || 0;
      });

      await api.put(`/exercises/${id}`, updatedData);
      setEditingExerciseId(null);
      const response = await api.get<Exercise[]>("/exercises");
      setExercises(response.data);
    } catch (error) {
      console.error("Error updating exercise:", error);
      setError("Failed to update exercise");
    }
  };

  const handleCancelEdit = () => {
    setEditingExerciseId(null);
    setEditedExercise({});
  };

  const handleDropdownChange = (
    event: React.SyntheticEvent<HTMLElement>,
    data: DropdownProps,
    field: string
  ) => {
    setEditedExercise((prevExercise) => ({
      ...prevExercise,
      [field]: data.value,
    }));
  };

  const handleToggleArchive = async (id: string, currentArchived: boolean) => {
    try {
      await api.put(`/exercises/${id}/archive`, { archived: !currentArchived });
      const response = await api.get<Exercise[]>("/exercises");
      const sortedExercises = response.data.sort((a, b) => {
        // First sort by archived status
        if (a.archived !== b.archived) {
          return a.archived ? 1 : -1;
        }
        // Then sort by muscle_group
        if (a.muscle_group !== b.muscle_group) {
          return a.muscle_group < b.muscle_group ? -1 : 1;
        }
        // Finally sort by name
        return a.name < b.name ? -1 : 1;
      });
      setExercises(sortedExercises);
    } catch (err) {
      console.error("Error toggling exercise archive status:", err);
      setError("Failed to update exercise archive status");
    }
  };

  if (isLoading) {
    return <p>Loading exercises...</p>;
  }

  if (error) {
    return <p className="error-message">Error: {error}</p>;
  }

  const renderEditableCell = (exercise: Exercise, field: string) => {
    const optionsMap: { [key: string]: string[] } = {
      type: allowedExerciseTypes,
      body_part: allowedBodyParts,
      muscle_group: allowedMuscleGroups,
      equipment: allowedEquipment,
      supporting_equipment: allowedSupportingEquipment,
      main_muscle: allowedMainMuscles,
    };
    const isDropdown = optionsMap.hasOwnProperty(field);

    if (editingExerciseId === exercise.id) {
      if (isDropdown) {
        return (
          <Dropdown
            value={editedExercise[field]}
            onChange={(e, data: DropdownProps) =>
              handleDropdownChange(e, data, field)
            }
            options={optionsMap[field].map((option) => ({
              key: option,
              text: option,
              value: option,
            }))}
            selection
            search
          />
        );
      }
      if (intensityFields.includes(field)) {
        return (
          <input
            type="number"
            value={editedExercise[field] ?? ""}
            onChange={(e) => handleExerciseInputChange(e, field)}
            min={0}
            max={5}
            step={1}
          />
        );
      } 
      if (field === "tip_1" || field === "tip_2" || field === "tip_3") {
        return (
          <input
            type="text"
            value={editedExercise[field] ?? ""}
            onChange={(e) => handleExerciseInputChange(e, field)}
          />
        );
      } else {
        return (
          <input
            type={defaultWeightFields.includes(field) ? "number" : "text"}
            value={editedExercise[field] ?? ""}
            onChange={(e) => handleExerciseInputChange(e, field)}
            min={defaultWeightFields.includes(field) ? 0 : undefined}
            step={defaultWeightFields.includes(field) ? 0.25 : undefined}
          />
        );
      }
    } else {
      if (field === "video_url") {
        return exercise[field] ? (
          <a href={exercise[field]} target="_blank" rel="noopener noreferrer">
            Video
          </a>
        ) : (
          ""
        );
      }
      return <span>{exercise[field] ?? ""}</span>;
    }
  };

  return (
    <Layout>
      <div className="admin-list-container">
        <h2>Exercises</h2>
        <div className="horizontal-buttons">
          <button onClick={() => navigate("/exercises/createedit")}>
            Upload Exercise CSV
          </button>
          <button>
            <CSVLink {...handleDownloadExercises()}>
              Download Exercise CSV
            </CSVLink>
          </button>
        </div>

        <div className="scrollable-table-container">
          <table className="admin-list-table">
            <thead>
              <tr>
                <th>Actions</th>
                {columnOrder.map((field) => (
                  <th key={field}>
                    {field.charAt(0).toUpperCase() +
                      field.slice(1).replace(/_/g, " ")}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {exercises.map((exercise) => (
                <tr key={exercise.id} className={exercise.archived ? "archived" : ""}>
                  <td>
                    <div className="actions">
                      {editingExerciseId === exercise.id ? (
                        <>
                          <button
                            onClick={() => handleSaveExercise(exercise.id)}
                            className="button"
                          >
                            <FaCheck title="Save Exercise" />
                          </button>
                          <button
                            onClick={handleCancelEdit}
                            className="button"
                          >
                            <FaTimes title="Cancel Edit" />
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            onClick={() => handleEditExercise(exercise)}
                            className="button"
                          >
                            <FaPencilAlt title="Edit Exercise" />
                          </button>
                          <button
                            onClick={() => handleToggleArchive(exercise.id, exercise.archived)}
                            className="button"
                            title={exercise.archived ? "Unarchive Exercise" : "Archive Exercise"}
                          >
                            {exercise.archived ? <FaTrashRestore /> : <FaTrash />}
                          </button>
                        </>
                      )}
                    </div>
                  </td>
                  {columnOrder.map((field) => (
                    <td key={field}>{renderEditableCell(exercise, field)}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </Layout>
  );
}

export default ExercisePage;