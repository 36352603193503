import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import api from './axiosConfig'; // Import axios for API requests
import "./WorkoutPlayer.css"; // Import the new CSS file
import moment from "moment-timezone"; // Import moment-timezone
import { FaCheckCircle, FaCircle, FaTrophy } from 'react-icons/fa';

interface TimerData {
  intervals: {
    duration: number;
    intervalType: string;
    exercise_number: number;
    setNumber?: number;
    nextExercise?: number;
    nextSetNumber?: number;
  }[];
  name: string;
  total_duration: number;
}

interface WorkoutTimerData {
  timer: TimerData;
  uniqueExerciseCount: number;
  maxSetNumber: number;
}

interface WorkoutData {
  timer_id: number;
  start_datetime: string;
  coach_first_name: string;
  playlists: Record<string, string>;
}

interface Milestone {
  customer_id: number;
  first_name: string;
  last_name: string;
  workout_number: number;
}

interface Exercise {
  name: string;
  short_name: string;
  video_url: string;
  exercise_number: number;
  set_type: string;
  exercise_id: string;
  workout_exercise_id: string;
  equipment: string;
  per_side: boolean;
}

function WorkoutPlayer() {
  const { locationId } = useParams<{ locationId: string }>();
  const workoutIdRef = useRef<string | null>(null);
  const [timerData, setTimerData] = useState<TimerData | null>(null);
  const [workoutData, setWorkoutData] = useState<WorkoutData | null>(null); // State to hold workout data
  const [currentIntervalIndex, setCurrentIntervalIndex] = useState(0);
  const [timeLeft, setTimeLeft] = useState(0);
  const [isRunning, setIsRunning] = useState(false); // Start the timer immediately
  const [isLoading, setIsLoading] = useState(true);
  const [uniqueExerciseCount, setUniqueExerciseCount] = useState<number>(0);
  const [maxSetNumber, setMaxSetNumber] = useState<number>(0);
  const [countdown, setCountdown] = useState<number | null>(null);
  // State variables to manage display transitions and progress bars
  const [displayState, setDisplayState] = useState("countdown"); // timer, firstTimers, milestones, personalBests

  // Replace the direct Audio declarations with a preloading system
  const [sounds, setSounds] = useState<Record<string, HTMLAudioElement>>({});

  const [milestones, setMilestones] = useState<Milestone[]>([]);

  // Add challengeExercises state
  const [challengeExercises, setChallengeExercises] = useState<Exercise[]>([]);

  useEffect(() => {
    // Create an async function to load sounds
    const loadSounds = async () => {
      const soundFiles = {
        go: "/sounds/Sound_Go.mp3",
        switch: "/sounds/Sound_Switch.mp3",
        longBreak: "/sounds/Sound_Long_Break.mp3",
        warmUp: "/sounds/Sound_Warm_Up.mp3",
        workoutComplete: "/sounds/Sound_Workout_Complete.mp3",
        workoutStart: "/sounds/Sound_Workout_Start.mp3",
        getReady: "/sounds/Sound_Get_Ready.mp3",
        sound20: "/sounds/Sound_20.mp3",
        sound10: "/sounds/Sound_10.mp3",
        sound3: "/sounds/Sound_3.mp3",
        sound2: "/sounds/Sound_2.mp3",
        sound1: "/sounds/Sound_1.mp3",
        sound20sToGetReady: "/sounds/Sound_20s_To_Get_Ready.mp3",
        break: "/sounds/Sound_Break.mp3",
        pleaseMove: "/sounds/Sound_Please_Move.mp3"
      };

      const loadedSounds: Record<string, HTMLAudioElement> = {};

      // Load each sound file
      for (const [key, path] of Object.entries(soundFiles)) {
        const audio = new Audio(path);
        // Preload the audio file
        try {
          await audio.load();
          // Optional: Prime the audio with a silent play (needs user interaction)
          const playPromise = audio.play();
          if (playPromise !== undefined) {
            playPromise
              .then(() => {
                audio.pause();
                audio.currentTime = 0;
              })
              .catch(e => console.log("Audio preload play silently failed, this is normal:", e));
          }
        } catch (e) {
          console.log(`Failed to preload ${key}:`, e);
        }
        loadedSounds[key] = audio;
      }

      setSounds(loadedSounds);
    };

    loadSounds();
  }, []);

  // Update the sound playing function to use the loaded sounds
  const playSound = (soundKey: string) => {
    const sound = sounds[soundKey];
    if (sound) {
      sound.currentTime = 0; // Reset the audio to start
      const playPromise = sound.play();
      if (playPromise !== undefined) {
        playPromise.catch(error => {
          console.log("Playback failed:", error);
        });
      }
    }
  };

  // Progress circle design elements
  const circleCircumference = 2 * Math.PI * 551; // Updated radius to match SVG

  const strokeDashArray =
    timerData?.intervals && timeLeft !== null
      ? `${((timerData.intervals[currentIntervalIndex].duration - timeLeft) /
        timerData.intervals[currentIntervalIndex].duration) *
      circleCircumference
      } ${circleCircumference}`
      : `0 ${circleCircumference}`;

  useEffect(() => {
    if (timerData && timeLeft === 0 && currentIntervalIndex === 0) {
      setTimeLeft(timerData?.intervals[0].duration);
    }
  }, [timerData]);

  // CODE TO LOAD WORKOUT INFORMATION


  const fetchMilestones = async (workoutId: string) => {
    try {
      const milestonesResponse = await api.get<Milestone[]>(`/workouts/${workoutId}/milestones`);
      setMilestones(milestonesResponse.data);
    } catch (error) {
      console.error("Error fetching milestones:", error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const workoutId = workoutIdRef.current;
        if (!workoutId) return;

        // 1. Fetch workout data
        const workoutResponse = await api.get<WorkoutData>(`/workouts/${workoutId}`);
        setWorkoutData(workoutResponse.data);

        try {
          const response = await api.get<WorkoutTimerData>(`/workouts/${workoutId}/timer`);
          const workoutTimerData = response.data;
          setTimerData(workoutTimerData.timer);
          setUniqueExerciseCount(workoutTimerData.uniqueExerciseCount);
          setMaxSetNumber(workoutTimerData.maxSetNumber);
        } catch (error) {
          console.error("Error fetching workout timer:", error);
        }

        await fetchMilestones(workoutId);
        await fetchChallengeExercises();
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    };
    if (workoutIdRef.current) {
      fetchData();
    }
  }, [workoutIdRef.current]); // Run only when workoutId changes

  const formattedTime = workoutData
    ? moment
      .tz(workoutData.start_datetime, "UTC")
      .tz("Europe/Berlin") // Convert to Berlin timezone
      .format("HH:mm") // Customize the format as needed
    : "";

  // CODE TO DISPLAY THE TIMER
  useEffect(() => {
    let intervalId: NodeJS.Timeout;

    if (!timerData) {
      return;
    }

    if (
      isRunning &&
      timerData &&
      currentIntervalIndex < timerData.intervals.length
    ) {
      intervalId = setInterval(() => {
        setTimeLeft((prevTimeLeft) => {
          if (prevTimeLeft > 1) {
            return prevTimeLeft - 1;
          } else {
            clearInterval(intervalId);
            if (currentIntervalIndex < timerData.intervals.length - 1) {
              const newIntervalIndex = currentIntervalIndex + 1;
              const newTimeLeft =
                timerData.intervals[newIntervalIndex].duration;

              setCurrentIntervalIndex(newIntervalIndex);
              setTimeLeft(newTimeLeft);
            } else {
              clearInterval(intervalId);
              setIsRunning(false);

              setDisplayState("thankYou");

              return 0;
            }
          }
          return 0; // Ensure a number is always returned
        });
      }, 1000); // Update every 1000ms (1 second)
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [isRunning, currentIntervalIndex, timerData, workoutIdRef.current]);

  const checkWorkoutStart = () => {
    // Only show countdown if we're at the start of the workout and not running
    if (!isRunning && currentIntervalIndex === 0 && timeLeft === timerData?.intervals[0]?.duration) {
      const startTime = moment(workoutData?.start_datetime);
      const now = moment();
      const diffInSeconds = startTime.diff(now, 'seconds');

      if (diffInSeconds > 0) {
        setCountdown(diffInSeconds);
        setDisplayState("countdown");
      } else {
        setCountdown(null);
        // Only set display state to timer if we're not in thankYou state
        if (displayState !== "thankYou") {
          setDisplayState("timer");
        }
      }
    } else {
      setCountdown(null);
      // Only set display state to timer if we're not in thankYou state
      if (displayState !== "thankYou") {
        setDisplayState("timer");
      }
    }
  };

  // WebSocket Connection
  const [ws, setWs] = useState<WebSocket | null>(null);
  const reconnectTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  // Add a new ref to track if an interval was skipped
  const wasSkippedRef = useRef(false);

  // Add a new ref to track the last sound played with its timestamp
  const lastSoundPlayedRef = useRef<{
    timeLeft: number;
    intervalIndex: number;
    timestamp: number;
  }>({
    timeLeft: 0,
    intervalIndex: 0,
    timestamp: 0,
  });

  useEffect(() => {
    const connectWebSocket = () => {
      const wsUrl = process.env.NODE_ENV === 'production'
        ? `wss://${new URL(process.env.REACT_APP_API_URL || '').host}`
        : "ws://localhost:5001";

      const newWs = new WebSocket(wsUrl);

      newWs.onopen = () => {
        console.log("WorkoutPlayer connected to WebSocket server");
        newWs.send(JSON.stringify({ type: "join", locationId }));
      };

      newWs.onmessage = (message) => {
        const data = JSON.parse(message.data);
        if (
          (data.type === "timerUpdate" || data.type === 'refreshTimer' || data.type === 'resetTimer') &&
          data.locationId === locationId
        ) {
          console.log("Received update:", data)
          // Only update workoutId if it's different
          if (workoutIdRef.current !== data.workoutId) {
            workoutIdRef.current = data.workoutId;
            console.log("Changed workoutIdRef to ", workoutIdRef.current);
            setDisplayState("countdown");
            // Reset countdown when workout changes
            setCountdown(null);
          }

          setCurrentIntervalIndex(data.timerState.intervalIndex);
          setTimeLeft(data.timerState.timeLeft);

          if (data.skipped) {
            wasSkippedRef.current = true;
          } else {
            wasSkippedRef.current = false;
          }

          setIsRunning(data.timerState.isRunning);
        }

        // Add handler for booking updates
        if (data.type === "bookingUpdate" && workoutIdRef.current && workoutIdRef.current === data.workoutId) {
          console.log("Booking update received, refreshing attendance data");
          fetchMilestones(workoutIdRef.current);
        }
      };

      newWs.onclose = () => {
        console.log("WebSocket connection closed. Attempting to reconnect...");
        reconnectTimeoutRef.current = setTimeout(connectWebSocket, 5000); // Attempt to reconnect after 5 seconds
      };

      newWs.onerror = (error) => {
        console.error("WebSocket error:", error);
        newWs.close();
      };

      setWs(newWs);
    };
    
    connectWebSocket();
    
    return () => {
      if (ws) {
        ws.close();
      }
      if (reconnectTimeoutRef.current) {
        clearTimeout(reconnectTimeoutRef.current);
      }
    };
  }, [locationId]);

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${String(minutes).padStart(2, "0")}:${String(
      remainingSeconds
    ).padStart(2, "0")}`;
  };

  const intervalType = timerData?.intervals?.[
    currentIntervalIndex
  ]?.intervalType
    ?.replace(/_/g, " ")
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");

  const displayText = timerData?.intervals?.[currentIntervalIndex]
    ?.exercise_number
    ? "Exercise"
    : intervalType === "Change Station"
      ? "Switch"
      : intervalType?.includes("Pause")
        ? "Break"
        : intervalType;

  const getStrokeColorForInterval = (intervalType: string) => {
    if (intervalType.includes("main") || intervalType.includes("challenge")) {
      return "var(--color-neon-blue)";
    } else if (
      intervalType.includes("break") ||
      intervalType.includes("switch") ||
      intervalType.includes("pause") ||
      intervalType.includes("stretching") ||
      intervalType.includes("breathing") ||
      intervalType.includes("celebration") ||
      intervalType.includes("station")
    ) {
      return "var(--color-grey)";
    } else if (intervalType.includes("warmup")) {
      return "var(--color-yellow)";
    } else {
      return "var(--color-cream)";
    }
  };

  const currentIntervalType =
    timerData?.intervals?.[currentIntervalIndex]?.intervalType || "";
  const strokeColor = getStrokeColorForInterval(currentIntervalType);

  // Function to calculate the segments for the progress bar
  const calculateSegments = (intervals: { duration: number; intervalType: string }[]) => {
    if (!intervals) return [];
    const segments: { duration: number; intervalType: string }[] = [];
    let currentSegment: { duration: number; intervalType: string } | null = null;

    intervals.forEach((interval, index) => {
      const isMainSet = interval.intervalType.includes("main");
      const isBreak = interval.intervalType.includes("break");
      const isSwitchOrPause = interval.intervalType.includes("switch") || interval.intervalType.includes("pause");

      if (isMainSet || isSwitchOrPause) {
        if (!currentSegment) {
          currentSegment = { duration: 0, intervalType: "main" };
        }
        currentSegment.duration += interval.duration;
      } else {
        if (currentSegment) {
          segments.push(currentSegment);
          currentSegment = null;
        }
        segments.push({ duration: interval.duration, intervalType: interval.intervalType });
      }

      if (isBreak && currentSegment) {
        segments.push(currentSegment);
        currentSegment = null;
      }

      if (index === intervals.length - 1 && currentSegment) {
        segments.push(currentSegment);
      }
    });
    return segments;
  };

  const segments = calculateSegments(timerData?.intervals || []);

  // Function to calculate the fill width for each segment
  const calculateFillWidth = (segments: { duration: number; intervalType: string }[], totalDurationPassed: number) => {
    let remainingDuration = totalDurationPassed;
    return segments.map(segment => {
      const fillWidth = Math.min(segment.duration, remainingDuration);
      remainingDuration -= fillWidth;
      return {
        ...segment,
        fillWidth: (fillWidth / segment.duration) * 100
      };
    });
  };

  const totalDurationPassed = (timerData?.intervals?.slice(0, currentIntervalIndex) ?? [])
    .reduce((acc, interval) => acc + (interval?.duration ?? 0), 0)
    + ((timerData?.intervals?.[currentIntervalIndex]?.duration ?? 0) - timeLeft);

  const filledSegments = calculateFillWidth(segments, totalDurationPassed);

  useEffect(() => {
    if (timerData && timerData.intervals.length > 0 && Object.keys(sounds).length > 0) {
      const currentInterval = timerData.intervals[currentIntervalIndex];
      const now = Date.now();

      // Play Workout Start sound one second after the first interval starts
      if (currentIntervalIndex === 0 && timeLeft === currentInterval.duration - 1) {
        playSound('workoutStart');
      }

      // Function to check if we should play a sound
      const shouldPlaySound = (timeLeft: number, intervalIndex: number) => {
        const lastPlayed = lastSoundPlayedRef.current;
        // Prevent playing the same sound within 2 seconds
        if (
          lastPlayed.timeLeft === timeLeft &&
          lastPlayed.intervalIndex === intervalIndex &&
          now - lastPlayed.timestamp < 2000
        ) {
          return false;
        }
        return true;
      };

      // Function to update last played sound
      const updateLastPlayed = (timeLeft: number) => {
        lastSoundPlayedRef.current = {
          timeLeft,
          intervalIndex: currentIntervalIndex,
          timestamp: now
        };
      };

      // Only play sounds if the interval wasn't skipped
      if (!wasSkippedRef.current) {
        // Play sound based on interval type and time left
        if (currentInterval.intervalType.includes("main") && timeLeft === 20) {
          if (shouldPlaySound(20, currentIntervalIndex)) {
            playSound('sound20');
            updateLastPlayed(20);
          }
        } else if (["switch", "pause", "long break", "setup"].includes(currentInterval.intervalType) && timeLeft === 10) {
          if (shouldPlaySound(10, currentIntervalIndex)) {
            playSound('sound10');
            updateLastPlayed(10);
          }
        } else if (timeLeft === currentInterval.duration) {
          if (shouldPlaySound(currentInterval.duration, currentIntervalIndex)) {
            if (currentInterval.intervalType.includes("main")) {
              playSound('go');
            } else {
              switch (currentInterval.intervalType) {
                case "switch":
                  playSound('switch');
                  break;
                case "pause":
                  playSound('break');
                  break;
                case "break":
                  playSound('break');
                  break;
                case "change_station":
                  playSound('break');
                  setTimeout(() => {
                    playSound('pleaseMove');
                  }, 2000);
                  break;
                case "warmup":
                  playSound('warmUp');
                  break;
                case "setup":
                  playSound('getReady');
                  break;
                case "challenge":
                  playSound('go');
                  // Check if this is the last interval
                  if (currentIntervalIndex === timerData.intervals.length - 1) {
                    playSound('workoutComplete');
                  }
                  break;
                default:
                  break;
              }
            }
            updateLastPlayed(currentInterval.duration);
          }
        }

        // Play countdown sounds with debounce check
        if (timeLeft <= 3 && timeLeft > 0 && !["break", "stretching", "breathing", "celebration"].some(type => currentInterval.intervalType.includes(type))) {
          if (shouldPlaySound(timeLeft, currentIntervalIndex)) {
            switch (timeLeft) {
              case 3:
                playSound('sound3');
                break;
              case 2:
                playSound('sound2');
                break;
              case 1:
                playSound('sound1');
                break;
            }
            updateLastPlayed(timeLeft);
          }
        }
      }
    }
  }, [timeLeft, currentIntervalIndex, timerData, sounds]);

  // Update the UI to display the exercise progress with blinking animation
  const renderExerciseProgress = () => {
    if (!timerData) return null;

    return (
      <div className="exercise-progress-list">
        <div className="exercise-progress-header">
          <div className="exercise-element">Exercises</div>
          {[...Array(maxSetNumber)].map((_, index) => (
            <div className="set-element" key={index}>Set {index + 1}</div>
          ))}
        </div>
        {Array.from({ length: uniqueExerciseCount }, (_, exerciseNumber) => (
          <div key={exerciseNumber} className="exercise-progress-row">
            <div className="exercise-element">Exercise {exerciseNumber + 1}</div>
            {[...Array(maxSetNumber)].map((_, setNumber) => {
              const isBreakInterval = timerData.intervals.some(interval =>
                ["break", "pause", "change_station", "setup"].includes(interval.intervalType)
              );
              return (
                <div
                  key={setNumber}
                  className="set-element"
                >
                  {timerData.intervals.some((interval, index) =>
                    interval.exercise_number === exerciseNumber + 1 &&
                    interval.setNumber === setNumber + 1 &&
                    currentIntervalIndex >= index) ? <FaCheckCircle /> : ""}
                  {isBreakInterval && timerData.intervals.some((interval, index) =>
                    interval.nextExercise === exerciseNumber + 1 &&
                    interval.nextSetNumber === setNumber + 1 &&
                    currentIntervalIndex === index) ? <FaCircle className="blinking" /> : ""}
                </div>
              );
            })}
          </div>
        ))}
      </div>
    );
  };

  useEffect(() => {
    if (timerData && workoutData) { // Only run when both are available
      checkWorkoutStart();
    }
  }, [timerData, workoutData, isRunning]); // Include all relevant dependencies

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (countdown !== null) {
        setCountdown((prevCountdown) => {
          if (prevCountdown !== null && prevCountdown > 0) {
            return prevCountdown - 1; // Decrement countdown
          }
          setDisplayState("timer"); // Reset display state to timer when countdown reaches zero
          return null; // Set to null when countdown reaches zero
        });
      }
    }, 1000); // Update countdown every second

    return () => {
      clearInterval(intervalId);
    };
  }, [countdown, workoutData]); // Run this effect when countdown or workoutData changes

  // Add function to fetch challenge exercises
  const fetchChallengeExercises = async () => {
    if (!workoutIdRef.current) return;

    try {
      const response = await api.get(`/workouts/${workoutIdRef.current}/exercises`);
      if (response.data && response.data.challenge) {
        setChallengeExercises(response.data.challenge);
      }
    } catch (error) {
      console.error("Error fetching challenge exercises:", error);
    }
  };

  // Update useEffect to fetch challenge exercises when workout ID is available
  useEffect(() => {
    if (workoutIdRef.current) {
      fetchChallengeExercises();
    }
  }, [workoutIdRef.current]);

  // Update the useEffect that handles display state changes
  useEffect(() => {
    if (timerData?.intervals && currentIntervalIndex < timerData.intervals.length) {
      const currentInterval = timerData.intervals[currentIntervalIndex];

      // Don't override countdown or thankYou display states
      if (displayState === "countdown" || displayState === "thankYou") {
        return;
      }

      // Determine the display state based on interval type
      if (currentInterval.intervalType === "challenge" ||
        currentInterval.intervalType === "stretching" ||
        currentInterval.intervalType === "breathing") {
        setDisplayState("blank");
      } else if (currentInterval.intervalType === "explanation" && timerData.intervals[currentIntervalIndex + 1].intervalType === "challenge") {
        setDisplayState("challenge");
      } else if (currentInterval.intervalType === "celebration") {
        setDisplayState("celebration");
      } else {
        setDisplayState("timer");
      }
    }
  }, [timerData, currentIntervalIndex, displayState]);

  // Add function to render challenge content
  const renderChallengeContent = () => {
    // If there are no challenge exercises, return null so we can show blank style instead
    if (!challengeExercises || challengeExercises.length === 0) {
        return null;
    }
    
    // Calculate progress percentage for current interval
    const currentInterval = timerData?.intervals[currentIntervalIndex];
    const progressPercentage = currentInterval
        ? ((currentInterval.duration - timeLeft) / currentInterval.duration) * 100
        : 0;
    
    return (
        <div className="challenge-container">
            <div className="challenge-top-element">AMRAP - Challenge
                <div className="challenge-timer-container">
                    <div className="challenge-timer-content">
                        <div className="challenge-time-left">{formatTime(timeLeft)}</div>
                    </div>
                    <div className="challenge-progress-bar-container">
                        <div className="challenge-progress-bar">
                            <div
                                className="challenge-progress-bar-fill"
                                style={{ width: `${progressPercentage}%` }}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="challenge-reps">- 6 reps each -</div>

            <div className="challenge-exercises">
                {challengeExercises.map((exercise, index) => (
                    <div key={index} className="challenge-exercise">
                        <div className="challenge-exercise-name">
                            {exercise.short_name || exercise.name}
                        </div>
                        <div className="exercise-video-box">
                            <div className="exercise-video">
                                <video
                                    src={exercise.video_url.replace('/upload/', '/upload/q_auto,w_640/')}
                                    autoPlay
                                    loop
                                    muted
                                />
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
  };

  const renderMilestoneContent = () => {
    // Calculate progress percentage for current interval
    const currentInterval = timerData?.intervals[currentIntervalIndex];
    const progressPercentage = currentInterval
      ? ((currentInterval.duration - timeLeft) / currentInterval.duration) * 100
      : 0;

    return (
      <div className="celebration-milestone-container">
        <h1 className="milestone-title">{milestones && milestones.length > 0 ? "Milestones" : "Props to all first timers"}</h1>

        <div className="milestone-customers">
          {milestones && milestones.length > 0 ? (
            milestones.map((milestone) => (
              <div key={milestone.customer_id} className="milestone-customer">
                <span className="milestone-customer-name">
                  {milestone.first_name} {milestone.last_name.slice(0, 2)}.
                </span>
                <div className="milestone-badge">
                  <FaTrophy /> {milestone.workout_number}
                </div>
              </div>
            ))
          ) : (
            <div className="no-milestones"></div>
          )}
        </div>

        <div className="milestone-progress-container">
          <div className="milestone-progress-bar">
            <div
              className="milestone-progress-fill"
              style={{ width: `${progressPercentage}%` }}
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div
      className={`workout-player-container 
        ${displayState === "blank" ? "blank" : ""} 
        ${displayState === "celebration" ? "celebration-bg" : ""}
        ${displayState === "challenge" && challengeExercises && challengeExercises.length > 0 ? "challenge-bg" : ""}`
      }
      style={{
        background: displayState === "timer" ? "var(--color-light-blue)" :
          displayState === "blank" || (displayState === "challenge" && (!challengeExercises || challengeExercises.length === 0)) ? "var(--color-black)" : 
          displayState === "challenge" ? "var(--color-light-blue)" : "var(--color-cream)",
      }}
    >
      {displayState !== "blank" && displayState !== "celebration" && displayState !== "challenge" && (
        <svg
          className="lifted-logo"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 249 44"
          fill="currentColor"
        >
          <path d="M0 43.52V0H7.296V37.376H25.216L19.0703 43.52H0Z" fill="currentColor" />
          <path d="M43.4375 43.52V0H50.7335V43.52H43.4375Z" fill="currentColor" />
          <path d="M70.0625 43.52V0H99.1185V6.144H77.3585V19.008H99.1185V25.152H77.3585V43.52H70.0625Z" fill="currentColor" />
          <path d="M127.802 43.52V6.144H116.283V0H146.555V6.144H135.098V43.52H127.802Z" fill="currentColor" />
          <path d="M164.688 43.52V0H193.616V6.144H171.984V18.304H193.616V24.32H171.984V37.376H193.616V43.52H164.688Z" fill="currentColor" />
          <path d="M212.5 43.52V0H227.156C239.764 0 248.596 8.768 248.596 21.504C248.596 34.56 239.764 43.52 227.156 43.52H212.5ZM219.796 37.376H226.196C235.412 37.376 241.364 31.04 241.364 21.504C241.364 12.288 235.412 6.144 226.196 6.144H219.796V37.376Z" fill="currentColor" />
        </svg>
      )}
      {isLoading ? (
        <p>Loading workout...</p>
      ) : (
        <>
          {displayState === "timer" && (
            <>
              <div className="workout-center-content">
                <div className="left-column">
                  <div className="workout-timer-circle">
                    <svg width="1200" height="1200" viewBox="0 0 1200 1200">
                      {/* Background Circle */}
                      <circle
                        cx="600"
                        cy="600"
                        r="590"
                        fill="none"
                        stroke="var(--color-dark-blue)"
                        strokeWidth="10"
                      />
                      {/* Content Circle */}
                      <circle
                        cx="600"
                        cy="600"
                        r="482"
                        fill="none"
                        stroke="var(--color-dark-blue)"
                        strokeWidth="10"
                      />
                      {/* Progress Circle */}
                      <circle
                        className="workout-timer-circle-progress"
                        cx="600"
                        cy="600"
                        r="536"
                        fill="none"
                        stroke={strokeColor}
                        strokeWidth="98"
                        strokeLinecap="square"
                        style={{
                          strokeDasharray: strokeDashArray,
                          transform: "rotate(270deg)",
                          transformOrigin: "center",
                        }}
                      />
                    </svg>
                    <div className="workout-timer-text">
                      <div className="workout-description-text">
                        {displayText}
                      </div>
                      <div className="workout-time-left">
                        {formatTime(timeLeft)}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="right-column">
                  {renderExerciseProgress()}
                </div>
              </div>

              <div className="progress-bar-container">
                {filledSegments.map((segment, index) => (
                  <div
                    key={index}
                    className="progress-bar-segment"
                    style={{
                      width: `${(segment.duration / (timerData?.total_duration ?? 0)) * 100}%`
                    }}
                  >
                    <div
                      className="progress-bar-fill"
                      style={{
                        width: `${segment.fillWidth}%`
                      }}
                    ></div>
                  </div>
                ))}
              </div>
            </>
          )}
          {(displayState === "blank" || (displayState === "challenge" && (!challengeExercises || challengeExercises.length === 0))) && (
            <div className="blank-timer-display">
              <div className="blank-interval-type">{intervalType || displayText}</div>
              <div className="blank-timer">{formatTime(timeLeft)}</div>
            </div>
          )}
          {displayState === "celebration" && renderMilestoneContent()}
          {displayState === "thankYou" && (
            <div className="celebration-container">
              <h2 className="celebration-subheader" style={{ color: 'var(--color-dark-blue)' }}>Thank you!</h2>
              <h3 className="celebration-header" style={{ color: 'var(--color-neon-blue)' }}>See you <span style={{ fontFamily: 'PPEditorialNew' }}>soon</span></h3>
            </div>
          )}
          {displayState === "countdown" && countdown !== null && (
            <div className="countdown-container">
              Workout starts in
              <div className="countdown-timer">
                <span className="minutes">{String(Math.floor(countdown / 60)).padStart(2, '0')}</span>:
                <span className="seconds">{String(countdown % 60).padStart(2, '0')}</span>
              </div>
              {countdown < 300 && (
                <div className="spot-pick-text">
                  Have you picked a spot?
                </div>
              )}
            </div>
          )}
          {displayState === "challenge" && challengeExercises && challengeExercises.length > 0 && renderChallengeContent()}
          
          {(displayState !== "blank" && !(displayState === "challenge" && (!challengeExercises || challengeExercises.length === 0)) && displayState !== "celebration") && (
            <div className="workout-footer">
              <p>Trainer <span style={{ fontFamily: 'PPEditorialNew' }}>{workoutData?.coach_first_name}</span></p>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default WorkoutPlayer;