import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useRef,
  ReactNode,
} from "react";
import { getAuth, onAuthStateChanged, signOut, User, signInWithCustomToken, UserCredential } from "firebase/auth";
import api from './axiosConfig';
import { useLocation, useNavigate } from "react-router-dom";
import { jwtDecode } from 'jwt-decode';



interface AuthContextType {
  user: User | null;
  userRoles: string[];
  isLoading: boolean;
  isAuthenticating: boolean;
  setIsAuthenticating: React.Dispatch<React.SetStateAction<boolean>>;
  login: (user: User) => Promise<void>;
  logout: () => void;
  refreshToken: () => Promise<string | null>;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

interface AuthProviderProps {
  children: ReactNode;
}

export function AuthProvider({ children }: AuthProviderProps) {
  //States
  const [user, setUser] = useState<User | null>(null);
  const [userRoles, setUserRoles] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isAuthenticating, setIsAuthenticating] = useState<boolean>(false);

  // Hooks
  const location = useLocation();
  const navigate = useNavigate();
  const unsubscribeAuthStateChangedRef = useRef<(() => void) | null>(null);

  // Check Cookies and URL Params for JWT
  useEffect(() => {
    const storedUser = localStorage.getItem("currentUser");
    setIsAuthenticating(true);
    if (storedUser) {
      console.log("Found stored user in cookies: ", JSON.parse(storedUser));
    } else {
      const urlParams = new URLSearchParams(window.location.search);
      const jwtToken = urlParams.get('token');
      if (jwtToken) {
        console.log("Found JWT in URL params:", jwtToken);
        authenticateWithJWT(jwtToken);
      } else {
        setIsLoading(false);
        setIsAuthenticating(false); // No user in cookies or JWT in URL, no login needed
      }
    }
  }, []); // Run only once on mount

  // Function to authenticate with JWT and transition to Firebase
  const authenticateWithJWT = async (token: string) => {
    try {
      
      // Verify the JWT with your server and get a custom token
      const response = await api.post('/auth/verify-token', { token });
      const customToken = response.data.customToken;

      // Use the custom token to authenticate with Firebase
      await authenticateWithCustomToken(customToken);
    } catch (error) {
      console.error("Error authenticating with JWT:", error);
      setIsLoading(false);
      setIsAuthenticating(false);
    }
  };

  // Function to authenticate with a custom token
  const authenticateWithCustomToken = async (customToken: string) => {
    const firebaseAuth = getAuth();
    try {
      const userCredential: UserCredential = await signInWithCustomToken(firebaseAuth, customToken);
      const user = userCredential.user;

      // Set the user object and roles as needed
      setUser(user);
      // Fetch roles if necessary, or handle them as part of the server response
      setUserRoles([]); // Assuming roles are handled separately
      localStorage.setItem("currentUser", JSON.stringify(user));
      console.log("User signed in with custom token:", user);
    } catch (error) {
      console.error("Error signing in with custom token:", error);
      setIsLoading(false);
      setIsAuthenticating(false);
    }
  };

  // Handle Firebase Auth State Changes and Subsequent Actions
  useEffect(() => {
    unsubscribeAuthStateChangedRef.current = onAuthStateChanged(
      getAuth(),
      (user) => {
        if (user) {
          console.log("Auth state changed to logged in:", user);
          login(user);
        } else {
          setUser(null);
          setUserRoles([]);
          setIsLoading(false);
          setIsAuthenticating(false);
        }
      }
    );

    return () => {
      if (unsubscribeAuthStateChangedRef.current)
        unsubscribeAuthStateChangedRef.current();
    };
  }, []);

  // Login Function (with Role Fetching and Redirection)
  const login = async (user: User) => {
    setUser(user);
    try {
      const rolesResponse = await api.get<string[]>(
        `/users/firebase-uid/${user.uid}/roles`
      );
      setUserRoles(rolesResponse.data);
      localStorage.setItem("currentUser", JSON.stringify(user));
      // Updated redirect logic
      const from = location.state?.from?.pathname;
      if (from) {
      navigate(from === '/login' ? '/' : from);
      } 

    } catch (error) {
      console.error("Error fetching user roles:", error);
    } finally {
      console.log("Logged in user:", user);
      setIsAuthenticating(false);
    }
  };

  // Add Axios Interceptor (after Authentication)
  useEffect(() => {
    let authInterceptor: number | null = null;

    const addAuthInterceptor = async () => {
      const currentUser = getAuth().currentUser; // Get the current user
      if (currentUser) {
        try {
          const idToken = await currentUser.getIdToken(true);

          authInterceptor = api.interceptors.request.use(
            async (config) => {
              const decodedToken: any = jwtDecode(idToken);
              const currentTime = Date.now() / 1000;

              // Check if token is about to expire in the next 5 minutes
              if (decodedToken.exp - currentTime < 300) {
                const newToken = await refreshToken();
                if (newToken) {
                  console.log("Interceptor token refreshed at: ", new Date().toISOString());
                  config.headers.Authorization = `Bearer ${newToken}`;
                }
              } else {
                config.headers.Authorization = `Bearer ${idToken}`;
              }
              return config;
            },
            (error) => {
              return Promise.reject(error);
            }
          );
        } catch (error) {
          console.error("Error getting ID token or adding interceptor:", error);
        }
      }
    };

    const removeAuthInterceptor = () => {
      if (authInterceptor !== null) {
        api.interceptors.request.eject(authInterceptor);
      }
    };

    // Directly await the interceptor addition
    if (user && !isAuthenticating) {
      console.log("Adding interceptor");
      addAuthInterceptor()
        .then(() => {
          console.log("Interceptor added successfully");
          setIsLoading(false);
        })
        .catch((error) => {
          console.error("Error adding interceptor:", error);
        });
    }

    return () => removeAuthInterceptor();
  }, [user, isAuthenticating]); // Dependencies are still correct

  // Logout Function
  const logout = () => {
    signOut(getAuth())
      .then(() => {
        setUser(null);
        setUserRoles([]);
        localStorage.removeItem("currentUser"); // Remove user from local storage
        navigate("/login");
      })
      .catch((error) => {
        console.error("Logout error:", error);
      });
  };

  // Method to refresh the token
  const isRefreshingToken = useRef(false);
  const refreshToken = async (): Promise<string | null> => {
    // Return existing promise if already refreshing
    if (isRefreshingToken.current) {
        console.log("Token refresh already in progress, waiting...");
        await new Promise(resolve => setTimeout(resolve, 1000));
        return refreshToken(); // Recursively retry
    }
    
    const currentUser = getAuth().currentUser;
    if (currentUser) {
        try {
            isRefreshingToken.current = true;
            const idToken = await currentUser.getIdToken(true); // Force refresh
            console.log("Token refreshed successfully");
            return idToken;
        } catch (error) {
            console.error("Error refreshing token:", error);
            return null;
        } finally {
            isRefreshingToken.current = false;
        }
    }
    return null;
  };

  useEffect(() => {
    const refreshInterval = 10 * 60 * 1000; // Refresh every 5 minutes

    const refreshTokenPeriodically = async () => {
      const currentUser = getAuth().currentUser;
      if (currentUser) {
        try {
          await currentUser.getIdToken(true); // Force refresh
          console.log("Token auto-refreshed at: ", new Date().toISOString());
        } catch (error) {
          console.error("Error refreshing token:", error);
        }
      }
    };

    const intervalId = setInterval(refreshTokenPeriodically, refreshInterval);

    return () => clearInterval(intervalId); // Cleanup on unmount
  }, []);

  // Add Axios Response Interceptor
  useEffect(() => {
    const responseInterceptor = api.interceptors.response.use(
      (response) => response,
      async (error) => {
        const originalRequest = error.config;

        // Check if the error is due to unauthorized access
        if (error.response && error.response.status === 401) {
          // Attempt to refresh the token
          const newToken = await refreshToken();
          if (newToken) {
            // If token refresh is successful, update the authorization header
            originalRequest.headers['Authorization'] = `Bearer ${newToken}`;
            // Retry the original request
            return api(originalRequest);
          } else {
            // Instead of reloading the page immediately
            console.error("Token refresh failed");
            // Only redirect to login if we've tried multiple times
            if (!originalRequest._retry) {
                originalRequest._retry = true;
                // Wait a bit and try again
                await new Promise(resolve => setTimeout(resolve, 2000));
                return api(originalRequest);
            } else {
                // After multiple failures, log out properly
                window.location.reload(); // Refresh the page
                return Promise.reject(error);
            }
          }
        }

        // If the error is not handled, reject the promise
        return Promise.reject(error);
      }
    );

    return () => {
      api.interceptors.response.eject(responseInterceptor);
    };
  }, [refreshToken]);

  // Context Value
  const value = {
    user,
    userRoles,
    isLoading,
    isAuthenticating,
    setIsAuthenticating,
    login,
    logout,
    refreshToken, // Expose the refreshToken method
  };

  return (
    <AuthContext.Provider value={value}>
      {!isLoading && children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
}
