import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import api from './axiosConfig';
import "./WorkoutWeightDisplay.css";
import "./WorkoutPlayer.css";
import { FaCheckCircle, FaCircle, FaTrophy } from 'react-icons/fa';
import { getPlateConfiguration } from './utils/weightUtils';
import moment from 'moment';
import { MdHorizontalRule } from "react-icons/md";

interface Exercise {
    name: string;
    short_name: string;
    reps: number;
    video_url: string;
    exercise_number: number;
    set_type: string;
    exercise_id: string;
    workout_exercise_id: string;
    equipment: string;
    per_side: boolean;
}

interface SpotGroup {
    spot: string;
    customer: {
        customerId: string;
        firstName: string;
        lastName: string;
        workoutNumber?: number;
        language?: string;
        correctionsOnly?: boolean;
    } | null;
    exercises: {
        exerciseId: string;
        weight: number;
    }[];
}

interface WeightDisplaySequence {
    intervalType: string;
    duration: number;
    displayState: string;
    displayGroup: number;
    setNumber: number;
    nextSetNumber: number;
}

// Define an interface for exerciseLogData
interface ExerciseLogData {
    customer_id: string;
    workout_id: string;
    exercise_id: string;
    equipment: string;
    set_number: number;
    set_type: string;
    reps: number;
    suggested_weight: number;
    exercise_number: number;
    actual_weight: number;
    timestamp: string;
}

interface Milestone {
    customer_id: number;
    first_name: string;
    last_name: string;
    workout_number: number;
}

function WorkoutWeightDisplay() {
    const { locationId, displayNumber } = useParams<{ locationId: string, displayNumber: string }>();
    const [exercises, setExercises] = useState<Exercise[]>([]);
    const [spotGroups, setSpotGroups] = useState<Record<string, SpotGroup>>({});
    const [error, setError] = useState<string | null>(null);
    const [weightDisplaySequence, setWeightDisplaySequence] = useState<WeightDisplaySequence[]>([]);
    const [currentIntervalIndex, setCurrentIntervalIndex] = useState(0);
    const [timeLeft, setTimeLeft] = useState(0);
    const [isRunning, setIsRunning] = useState(false);
    const workoutIdRef = useRef<string | null>(null);
    const [loading, setLoading] = useState(true);
    const [countdown, setCountdown] = useState<number | null>(null);
    const [startDateTime, setStartDateTime] = useState<string | null>(null);
    const [coachFirstName, setCoachFirstName] = useState<string | null>(null);
    const [displayState, setDisplayState] = useState<string>("normal");
    const [milestones, setMilestones] = useState<Milestone[]>([]);
    const [challengeExercises, setChallengeExercises] = useState<Exercise[]>([]);

    // WebSocket Connection
    const [ws, setWs] = useState<WebSocket | null>(null);
    const reconnectTimeoutRef = useRef<NodeJS.Timeout | null>(null);

    const [submittedLogs, setSubmittedLogs] = useState<Set<string>>(new Set());

    const dataFetchedRef = useRef(false);

    // Add a ref to track retry attempts
    const retryAttemptsRef = useRef<Record<string, number>>({});
    const MAX_RETRY_ATTEMPTS = 3;
    const RETRY_DELAY = 2000; // 2 seconds between retries

    // Define fetchData function with retry logic
    const fetchData = async (id: string, retryCount = 0) => {
        try {
            const response = await api.get(`/workouts/${id}/weights/${displayNumber}`);
            setExercises(response.data.exercisesToDisplay);
            setSpotGroups(response.data.spotGroups);
            setWeightDisplaySequence(response.data.weightDisplaySequence);
            setStartDateTime(response.data.start_datetime);
            setCoachFirstName(response.data.coach_first_name);
            setLoading(false);
            setError(null); // Clear any previous errors when fetch is successful
            dataFetchedRef.current = true; // Mark data as fetched

            // Reset retry counter on success
            retryAttemptsRef.current[id] = 0;

            // Fetch milestones for this workout
            await fetchMilestones(id);

            // Fetch challenge exercises for this workout
            await fetchChallengeExercises();

        } catch (error) {
            const err = error as { response?: { data?: { error: string }, status?: number } };
            
            // Check if this is an authentication error (401)
            if (err.response?.status === 401 && retryCount < MAX_RETRY_ATTEMPTS) {
                console.log(`Authentication error, retrying (${retryCount + 1}/${MAX_RETRY_ATTEMPTS}) in ${RETRY_DELAY}ms...`);
                
                // Set a temporary loading message
                setError(`Reconnecting... (${retryCount + 1}/${MAX_RETRY_ATTEMPTS})`);
                
                // Retry after delay
                setTimeout(() => {
                    fetchData(id, retryCount + 1);
                }, RETRY_DELAY);
                
                return;
            }
            
            // If we've exhausted retries or it's not an auth error, show the error
            setError(err.response?.data?.error || "An error occurred");
            setLoading(false);
        }
    };

    // Apply the same retry logic to fetchMilestones
    const fetchMilestones = async (workoutId: string, retryCount = 0) => {
        try {
            const milestonesResponse = await api.get<Milestone[]>(`/workouts/${workoutId}/milestones`);
            setMilestones(milestonesResponse.data);
        } catch (error) {
            const err = error as { response?: { status?: number } };
            
            // Check if this is an authentication error (401)
            if (err.response?.status === 401 && retryCount < MAX_RETRY_ATTEMPTS) {
                console.log(`Authentication error in fetchMilestones, retrying (${retryCount + 1}/${MAX_RETRY_ATTEMPTS}) in ${RETRY_DELAY}ms...`);
                
                // Retry after delay
                setTimeout(() => {
                    fetchMilestones(workoutId, retryCount + 1);
                }, RETRY_DELAY);
                
                return;
            }
            
            console.error("Error fetching milestones:", error);
        }
    };

    // Apply the same retry logic to fetchChallengeExercises
    const fetchChallengeExercises = async (retryCount = 0) => {
        if (!workoutIdRef.current) return;

        try {
            const response = await api.get(`/workouts/${workoutIdRef.current}/exercises`);
            if (response.data && response.data.challenge) {
                setChallengeExercises(response.data.challenge);
            }
        } catch (error) {
            const err = error as { response?: { status?: number } };
            
            // Check if this is an authentication error (401)
            if (err.response?.status === 401 && retryCount < MAX_RETRY_ATTEMPTS) {
                console.log(`Authentication error in fetchChallengeExercises, retrying (${retryCount + 1}/${MAX_RETRY_ATTEMPTS}) in ${RETRY_DELAY}ms...`);
                
                // Retry after delay
                setTimeout(() => {
                    fetchChallengeExercises(retryCount + 1);
                }, RETRY_DELAY);
                
                return;
            }
            
            console.error("Error fetching challenge exercises:", error);
        }
    };

    useEffect(() => {
        const connectWebSocket = () => {
            const wsUrl = process.env.NODE_ENV === 'production'
                ? `wss://${new URL(process.env.REACT_APP_API_URL || '').host}`
                : "ws://localhost:5001";

            const newWs = new WebSocket(wsUrl);

            newWs.onopen = () => {
                console.log("WorkoutWeightDisplay connected to WebSocket server");
                newWs.send(JSON.stringify({ type: "join", locationId }));
            };

            newWs.onmessage = (message) => {
                console.log("WebSocket message received:", message.data);
                const data = JSON.parse(message.data);
                if ((data.type === 'refreshTimer' || data.type === 'resetTimer') &&
                    data.locationId === locationId) {
                    workoutIdRef.current = data.workoutId;
                    setCurrentIntervalIndex(data.timerState.intervalIndex);
                    setTimeLeft(data.timerState.timeLeft);
                    setIsRunning(data.timerState.isRunning);
                    fetchData(data.workoutId)
                    setDisplayState("normal");
                } else if (data.type === "timerUpdate" &&
                    data.locationId === locationId) {
                    workoutIdRef.current = data.workoutId;
                    setCurrentIntervalIndex(data.timerState.intervalIndex);
                    setTimeLeft(data.timerState.timeLeft);
                    setIsRunning(data.timerState.isRunning);

                    if (!dataFetchedRef.current) {
                        fetchData(data.workoutId);
                    }
                } else if (data.type === "countdownSync" &&
                    data.locationId === locationId) {
                    setCountdown(data.countdown);
                }
                // Add handler for workoutComplete message
                else if (data.type === "workoutComplete" &&
                    data.locationId === locationId) {
                    setDisplayState("thankYou");
                }
                if (data.type === "bookingUpdate" && workoutIdRef.current === data.workoutId) {
                    console.log("Booking update received with matching workoutId:", workoutIdRef.current);
                    fetchData(data.workoutId);
                }
            };

            newWs.onclose = () => {
                console.log("WebSocket connection closed. Attempting to reconnect...");
                reconnectTimeoutRef.current = setTimeout(connectWebSocket, 5000);
            };

            newWs.onerror = (error) => {
                console.error("WebSocket error:", error);
                newWs.close();
            };

            setWs(newWs);
        };

        connectWebSocket();

        return () => {
            if (ws) {
                ws.close();
            }
            if (reconnectTimeoutRef.current) {
                clearTimeout(reconnectTimeoutRef.current);
            }
        };
    }, [locationId, displayNumber]);

    useEffect(() => {
        let intervalId: NodeJS.Timeout;

        if (isRunning && weightDisplaySequence.length > 0) {
            intervalId = setInterval(() => {
                setTimeLeft((prevTimeLeft) => {
                    if (prevTimeLeft > 1) {
                        return prevTimeLeft - 1;
                    } else {
                        clearInterval(intervalId);
                        if (currentIntervalIndex < weightDisplaySequence.length - 1) {
                            const newIntervalIndex = currentIntervalIndex + 1;
                            const newTimeLeft = weightDisplaySequence[newIntervalIndex].duration;

                            setCurrentIntervalIndex(newIntervalIndex);
                            setTimeLeft(newTimeLeft);
                        } else {
                            setIsRunning(false);
                        }
                        return 0;
                    }
                });
            }, 1000);
        }

        return () => clearInterval(intervalId);
    }, [isRunning, currentIntervalIndex, weightDisplaySequence]);

    useEffect(() => {
        const checkWorkoutStart = () => {
            if (!isRunning && currentIntervalIndex === 0 && timeLeft === weightDisplaySequence[0]?.duration) {
                const startTime = moment(startDateTime);
                const now = moment();
                const diffInSeconds = startTime.diff(now, 'seconds');

                if (diffInSeconds > 0) {
                    setCountdown(diffInSeconds);
                } else {
                    setCountdown(null);
                }
            } else {
                setCountdown(null);
            }
        };

        if (weightDisplaySequence.length > 0 && startDateTime) {
            checkWorkoutStart();
        }
    }, [weightDisplaySequence, startDateTime, isRunning, currentIntervalIndex, timeLeft]);

    useEffect(() => {
        const intervalId = setInterval(() => {
            if (countdown !== null && countdown > 0) {
                setCountdown(prev => prev !== null ? prev - 1 : null);
            }
        }, 1000);

        return () => clearInterval(intervalId);
    }, [countdown]);

    const formatTime = (seconds: number) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${String(minutes).padStart(2, "0")}:${String(remainingSeconds).padStart(2, "0")}`;
    };

    const currentInterval = weightDisplaySequence[currentIntervalIndex];
    const currentIntervalType = currentInterval?.intervalType
        ?.replace(/_/g, " ")
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
    const exercisesToDisplay = exercises.filter(exercise => exercise.set_type === currentIntervalType);
    const displayText = currentIntervalType?.includes("Main")
        ? "Exercise"
        : currentIntervalType?.includes("Change")
            ? "Long Break"
            : currentIntervalType?.includes("Pause")
                ? "Break"
                : currentIntervalType;

    // Sort exercises by exercise number
    exercisesToDisplay.sort((a, b) => a.exercise_number - b.exercise_number);

    const createExerciseLog = async (exerciseLogData: ExerciseLogData) => {
        const logKey = `${exerciseLogData.customer_id}-${exerciseLogData.exercise_id}-${exerciseLogData.set_number}-${exerciseLogData.actual_weight}`;

        if (!submittedLogs.has(logKey)) {
            try {
                await api.post('/exercise_logs', exerciseLogData);
                setSubmittedLogs(prev => new Set(prev).add(logKey));
            } catch (error) {
                console.error("Error creating exercise log:", error);
            }
        }
    };

    const handleIntervalEnd = () => {
        const currentInterval = weightDisplaySequence[currentIntervalIndex];
        if (!currentInterval || !currentInterval.setNumber) return;

        Object.values(spotGroups).forEach(spotGroup => {
            // Check if spot number matches current display group
            const spotNumber = parseInt(spotGroup.spot[0]);
            if (spotGroup.customer && spotNumber === currentInterval.displayGroup) {
                exercises.forEach(exercise => {
                    if (workoutIdRef.current && spotGroup.customer) {
                        const exerciseLogData = {
                            customer_id: spotGroup.customer.customerId,
                            workout_id: workoutIdRef.current,
                            exercise_id: exercise.exercise_id,
                            equipment: exercise.equipment,
                            set_number: currentInterval.setNumber,
                            set_type: exercise.set_type,
                            reps: exercise.reps,
                            suggested_weight: spotGroup.exercises.find(e => e.exerciseId === exercise.exercise_id)?.weight || 0,
                            exercise_number: exercise.exercise_number,
                            actual_weight: spotGroup.exercises.find(e => e.exerciseId === exercise.exercise_id)?.weight || 0,
                            timestamp: new Date().toISOString()
                        };
                        createExerciseLog(exerciseLogData);
                    }
                });
            }
        });
    };

    useEffect(() => {
        if (timeLeft === 1 && isRunning && currentInterval?.setNumber) {
            handleIntervalEnd();
        }
    }, [timeLeft, isRunning, currentInterval?.setNumber]);

    // Calculate progress percentage for current interval
    const progressPercentage = currentInterval
        ? ((currentInterval.duration - timeLeft) / currentInterval.duration) * 100
        : 0;

    const renderSetProgress = () => {
        if (!weightDisplaySequence.length) return null;

        // Find max set number from weightDisplaySequence
        const maxSetNumber = Math.max(...weightDisplaySequence
            .filter(interval => interval.setNumber)
            .map(interval => interval.setNumber || 0)
        );

        return (
            <div className="weight-set-progress-container">
                <div className="weight-set-progress-header">
                    {[...Array(maxSetNumber)].map((_, index) => (
                        <div key={index} className="weight-set-element">Set {index + 1}</div>
                    ))}
                </div>
                <div className="weight-set-progress-row">
                    {[...Array(maxSetNumber)].map((_, setNumber) => {
                        const isBreakInterval = ["break", "pause", "change_station", "setup"].includes(
                            weightDisplaySequence[currentIntervalIndex]?.intervalType.toLowerCase()
                        );

                        return (
                            <div key={setNumber} className="weight-set-element">
                                {weightDisplaySequence.some((interval, index) =>
                                    (interval.setNumber >= setNumber + 1 || interval.nextSetNumber > setNumber + 1) &&
                                    currentIntervalIndex === index
                                ) ? <FaCheckCircle /> : ""}
                                {isBreakInterval && weightDisplaySequence.some((interval, index) =>
                                    interval.nextSetNumber === setNumber + 1 &&
                                    currentIntervalIndex === index
                                ) ? <FaCircle className="blinking" /> : ""}
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    };

    const renderTimerContent = () => {
        if (countdown !== null && !isRunning) {
            return (
                <>
                    <div className="weight-description-text">Workout starts in</div>
                    <div className="weight-time-left">{formatTime(countdown)}</div>
                </>
            );
        }

        return (
            <>
                <div className="weight-description-text">{displayText}</div>
                <div className="weight-time-left">{formatTime(timeLeft)}</div>
            </>
        );
    };

    // Add a function to render challenge content
    const renderChallengeContent = () => {
        // If there are no challenge exercises, return null so we can show blank style instead
        if (!challengeExercises || challengeExercises.length === 0) {
            return null;
        }

        // Calculate progress percentage for current interval
        const progressPercentage = currentInterval
            ? ((currentInterval.duration - timeLeft) / currentInterval.duration) * 100
            : 0;

        return (
            <div className="challenge-container">
                <div className="challenge-top-element">AMRAP - Challenge
                    <div className="weight-timer-container">
                        <div className="weight-timer-content">
                            <div className="weight-time-left">{formatTime(timeLeft)}</div>
                        </div>
                        <div className="weight-progress-bar-container">
                            <div className="weight-progress-bar">
                                <div
                                    className="weight-progress-bar-fill"
                                    style={{ width: `${progressPercentage}%` }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="challenge-reps">- 6 reps each -</div>

                <div className="challenge-exercises">
                    {challengeExercises.map((exercise, index) => (
                        <div key={index} className="challenge-exercise">
                            <div className="challenge-exercise-name">
                                {exercise.short_name || exercise.name}
                            </div>
                            <div className="exercise-video-box">
                                <div className="exercise-video">
                                    <video
                                        src={exercise.video_url.replace('/upload/', '/upload/q_auto,w_640/')}
                                        autoPlay
                                        loop
                                        muted
                                    />
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        );
    };

    // Add a function to render milestone content
    const renderMilestoneContent = () => {
        // Calculate progress percentage for current interval
        const progressPercentage = currentInterval
            ? ((currentInterval.duration - timeLeft) / currentInterval.duration) * 100
            : 0;

        return (
            <div className="celebration-milestone-container">
                <h1 className="milestone-title">{milestones && milestones.length > 0 ? "Milestones" : "Props to all first timers"}</h1>

                <div className="milestone-customers">
                    {milestones && milestones.length > 0 ? (
                        milestones.map((milestone) => (
                            <div key={milestone.customer_id} className="milestone-customer">
                                <span className="milestone-customer-name">
                                    {milestone.first_name} {milestone.last_name.slice(0, 2)}.
                                </span>
                                <div className="milestone-badge">
                                    <FaTrophy /> {milestone.workout_number}
                                </div>
                            </div>
                        ))
                    ) : (
                        <div className="no-milestones"></div>
                    )}
                </div>

                <div className="milestone-progress-container">
                    <div className="milestone-progress-bar">
                        <div
                            className="milestone-progress-fill"
                            style={{ width: `${progressPercentage}%` }}
                        />
                    </div>
                </div>
            </div>
        );
    };

    // Update the useEffect that handles display state changes
    useEffect(() => {
        if (weightDisplaySequence.length > 0 && currentIntervalIndex < weightDisplaySequence.length && displayState !== "thankYou") {
            const currentInterval = weightDisplaySequence[currentIntervalIndex];

            // Determine the display state based on interval type
            if (currentInterval.intervalType === "celebration") {
                setDisplayState("celebration");
            } else if (currentInterval.displayState === 'challenge') {
                setDisplayState("challenge");
            } else if (currentInterval.displayState === 'blank') {
                setDisplayState("blank");
            } else {
                setDisplayState("normal");
            }
        }
    }, [currentIntervalIndex, weightDisplaySequence, displayState]);

    return (
        <div className={`workout-weight-display ${currentInterval?.displayState === 'blank' || (currentInterval?.displayState === 'challenge' && challengeExercises.length === 0) ? 'blank' : ''} ${displayState === 'thankYou' ? 'thank-you-display' : ''} ${displayState === 'celebration' ? 'celebration-bg' : ''} ${displayState === 'challenge' && challengeExercises && challengeExercises.length > 0 ? 'challenge-bg' : ''}`}>
            {(displayState === "thankYou") && (
                <svg
                    className={`lifted-logo ${currentInterval?.displayState === 'blank' && displayState !== "thankYou" ? 'inverted' : ''}`}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 249 44"
                    fill="currentColor" // This will inherit the color from CSS
                >
                    <path d="M0 43.52V0H7.296V37.376H25.216L19.0703 43.52H0Z" fill="currentColor" />
                    <path d="M43.4375 43.52V0H50.7335V43.52H43.4375Z" fill="currentColor" />
                    <path d="M70.0625 43.52V0H99.1185V6.144H77.3585V19.008H99.1185V25.152H77.3585V43.52H70.0625Z" fill="currentColor" />
                    <path d="M127.802 43.52V6.144H116.283V0H146.555V6.144H135.098V43.52H127.802Z" fill="currentColor" />
                    <path d="M164.688 43.52V0H193.616V6.144H171.984V18.304H193.616V24.32H171.984V37.376H193.616V43.52H164.688Z" fill="currentColor" />
                    <path d="M212.5 43.52V0H227.156C239.764 0 248.596 8.768 248.596 21.504C248.596 34.56 239.764 43.52 227.156 43.52H212.5ZM219.796 37.376H226.196C235.412 37.376 241.364 31.04 241.364 21.504C241.364 12.288 235.412 6.144 226.196 6.144H219.796V37.376Z" fill="currentColor" />
                </svg>
            )}
            {loading ? (
                <>
                    <div className="loading-message">Loading workout...</div>
                </>
            ) : error ? (
                <div className="error-message">{error}</div>
            ) : displayState === "thankYou" ? (
                <>
                    <div className="celebration-container">
                        <h2 className="celebration-subheader">Thank you!</h2>
                        <h3 className="celebration-header">See you <span style={{ fontFamily: 'PPEditorialNew' }}>soon</span></h3>
                    </div>
                    <div className="workout-footer">
                        <p>Trainer <span style={{ fontFamily: 'PPEditorialNew' }}>{coachFirstName}</span></p>
                    </div>
                </>
            ) : displayState === "celebration" ? (
                renderMilestoneContent()
            ) : displayState === "challenge" && challengeExercises && challengeExercises.length > 0 ? (
                <>
                    {renderChallengeContent()}
                    <div className="workout-footer">
                        <p>Trainer <span style={{ fontFamily: 'PPEditorialNew' }}>{coachFirstName}</span></p>
                    </div>
                </>
            ) : displayState === "challenge" && (!challengeExercises || challengeExercises.length === 0) ? (
                <div className="blank-timer-display">
                    <div className="blank-interval-type">{currentIntervalType || displayText}</div>
                    <div className="blank-timer">{formatTime(timeLeft)}</div>
                </div>
            ) : currentInterval?.displayState === 'blank' && currentInterval?.displayState ? (
                <div className="blank-timer-display">
                    <div className="blank-interval-type">{currentIntervalType || displayText}</div>
                    <div className="blank-timer">{formatTime(timeLeft)}</div>
                </div>
            ) : (
                <>
                    <div className="station-title">
                        <div>{`Station ${displayNumber}: ${exercises[0] ? exercises[0].short_name || exercises[0].name : ""}`}</div>
                        {exercises[0] && <div>{`- ${exercises[0].reps} Reps ${exercises[0].per_side ? "/ side" : ""} -`}</div>}
                    </div>
                    <div className="exercise-customer-container">
                        <div className="exercise-section">
                            {exercises[0] && (
                                <div className="exercise-info">
                                    <div className="exercise-video-box">
                                        <div className="exercise-video">
                                            <video
                                                src={exercises[0].video_url.replace('/upload/', '/upload/q_auto,w_640/')}
                                                autoPlay
                                                loop
                                                muted
                                            />
                                        </div>
                                    </div>
                                </div>
                            )}
                            <div className="weight-timer-container">
                                <div className="weight-timer-content">
                                    {renderTimerContent()}
                                </div>
                                {(countdown === null || isRunning) && (
                                    <div className="weight-progress-bar-container">
                                        <div className="weight-progress-bar">
                                            <div
                                                className="weight-progress-bar-fill"
                                                style={{ width: `${progressPercentage}%` }}
                                            />
                                        </div>
                                    </div>
                                )}
                            </div>
                            {renderSetProgress()}
                        </div>
                        <div className="customer-section">
                            {Object.values(spotGroups)
                                .filter(spotGroup => {
                                    const spotNumber = parseInt(spotGroup.spot[0]);
                                    return spotNumber === currentInterval?.displayGroup &&
                                        (currentInterval?.displayState === 'spots' || spotGroup.customer);
                                })
                                .sort((a, b) => a.spot[1].localeCompare(b.spot[1]))
                                .map((spotGroup, index) => (
                                    <div key={index} className="customer-box">
                                        <div className="spot-label">
                                            {`${displayNumber}${spotGroup.spot.slice(1)}`}
                                        </div>
                                        <div className="customer-name">
                                            {spotGroup.customer ?
                                                `${spotGroup.customer.firstName.length > 11
                                                    ? spotGroup.customer.firstName.slice(0, 11) + '.'
                                                    : spotGroup.customer.firstName} ${spotGroup.customer.lastName.slice(0, 2)}.` :
                                                ''}
                                        </div>
                                        <div className="customer-weight">
                                            {spotGroup.exercises[0]?.weight ? (
                                                <>
                                                    {`${Number(spotGroup.exercises[0].weight) % 1 === 0 ?
                                                        Math.floor(spotGroup.exercises[0].weight) :
                                                        spotGroup.exercises[0].weight} kg`}
                                                    {exercises[0]?.equipment === "Barbell" && (
                                                        <div className="plate-suggestion">
                                                            {getPlateConfiguration(spotGroup.exercises[0].weight)}
                                                        </div>
                                                    )}
                                                </>
                                            ) : ''}
                                        </div>
                                        {spotGroup.customer && (
                                            <div className="customer-details">
                                                {spotGroup.customer.workoutNumber && (
                                                    <div className={`workout-number ${spotGroup.customer.workoutNumber === 1 ? 'first-workout' :
                                                        (spotGroup.customer.workoutNumber <= 5 ||
                                                            spotGroup.customer.workoutNumber === 10 ||
                                                            spotGroup.customer.workoutNumber === 25 ||
                                                            spotGroup.customer.workoutNumber % 50 === 0) ? 'milestone-workout' : ''
                                                        }`}>
                                                        {`${spotGroup.customer.workoutNumber}`}
                                                    </div>
                                                )}
                                                {spotGroup.customer.language && (
                                                    <div className="language">
                                                        {spotGroup.customer.language.toUpperCase()}
                                                    </div>
                                                )}
                                                {spotGroup.customer.correctionsOnly && (
                                                    <div className="corrections filled" title="Only corrections, no pushing today">
                                                        <MdHorizontalRule />
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                ))}
                        </div>
                    </div>
                    {coachFirstName && displayState !== "celebration" && (
                        <div className="coach-name">Trainer {coachFirstName}</div>
                    )}
                </>
            )}
        </div>
    );
}

export default WorkoutWeightDisplay;
