import React, { useState } from 'react';
import CloseIcon from "@mui/icons-material/Close";

interface LocationSpotSelectionProps {
    floorplanUrl: string;
    maxSpots: number;
    bookedSpots: string[];
    onClose: () => void;
    onSave: (selectedSpot: string) => Promise<boolean>;
    greeting: string;
    displayNumber?: string;
}

const LocationSpotSelection: React.FC<LocationSpotSelectionProps> = ({
    floorplanUrl,
    maxSpots,
    bookedSpots,
    onClose,
    onSave,
    greeting,
    displayNumber
}) => {
    const [selectedSpot, setSelectedSpot] = useState<string | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleSpotClick = (spot: string) => {
        setSelectedSpot(spot);
        setError(null);
    };

    const handleSave = async () => {
        if (selectedSpot && !isSubmitting) {
            setIsSubmitting(true);
            setError(null);
            
            try {
                const success = await onSave(selectedSpot);
                if (!success) {
                    setError("Oops! This spot is no longer available. Please select another one.");
                    // Refresh the list of booked spots
                    // You'll need to implement this through a prop
                }
            } catch (error) {
                setError("Failed to save spot. Please try again.");
            } finally {
                setIsSubmitting(false);
            }
        }
    };

    const getSpots = () => {
        const totalSpots = Math.floor(maxSpots / 6);
        const letters = Array.from({ length: totalSpots }, (_, i) => String.fromCharCode(65 + i)); // Generate letters A, B, C, etc.
        const spots: string[] = [];

        // Generate all spots with numbers first then letters if displayNumber is provided
        for (let i = 1; i <= 6; i++) {
            for (const letter of letters) {
                spots.push(`${i}${letter}`); 
            }
        }
        
        return spots;
    };
    
    return (
        <>
            <div className="flex-vertical gap-xs">
                <div className="top-info-buttons">
                    <button className="button-m secondary square" onClick={onClose}>
                        <CloseIcon className="icon-l" />
                    </button>
                </div>
                <div className="flex-vertical">
                    <h2>{greeting}</h2>
                    <h1>select a spot!</h1>
                </div>
                {error && <div className="error-message-s">{error}</div>}
            </div>
            <div className="spots-container">
                {getSpots().map(spot => {
                    const isBooked = bookedSpots.includes(spot);
                    const isSelected = selectedSpot === spot;

                    return (
                        <button
                            key={spot}
                            className={`button-circle large ${isSelected ? 'active' : ''}`}
                            onClick={() => !isBooked && handleSpotClick(spot)}
                            disabled={isBooked}
                        >
                            {spot}
                        </button>
                    );
                })}
            </div>
            <button 
                className="button-m special" 
                onClick={handleSave}
                disabled={!selectedSpot || isSubmitting}
            >
                {isSubmitting ? "Saving..." : "Save"}
            </button>
        </>
    );
};

export default LocationSpotSelection;
