import React, { useState, useEffect, useRef } from "react";
import api from './axiosConfig';
import "./TimerPage.css";
import "./AdminForm.css";
import "./AdminList.css";
import {
  FaTrashAlt,
  FaPencilAlt,
  FaPlay,
  FaCopy,
  FaArrowUp,
  FaArrowDown,
} from "react-icons/fa";
import Layout from "./Layout";

// Define types for intervals and timers
interface Interval {
  duration: number;
  intervalType: string;
  exercise_number: number | "";
  display_type: string;
}

interface Timer {
  id: string;
  name: string;
  intervals: Interval[];
  total_duration?: number;
}

function TimerPage() {
  const [intervals, setIntervals] = useState<Interval[]>([]);
  const [timerName, setTimerName] = useState<string>("");
  const [bsportId, setBsportId] = useState<string>(""); // New state for bsport_id
  const [savedTimers, setSavedTimers] = useState<Record<string, Timer>>({});
  const [newInterval, setNewInterval] = useState<Interval>({
    duration: 30,
    intervalType: "",
    exercise_number: "",
    display_type: "",
  });
  const [errorMessage, setErrorMessage] = useState<string>(""); // State for error messages

  const intervalTypeOptions = [
    "main",
    "switch",
    "pause",
    "break",
    "change_station",
    "explanation",
    "setup",
    "warmup",
    "stretching",
    "breathing",
    "challenge",
    "celebration",
  ];

  const displayTypeOptions = ["all", "spot"];

  const intervalsRef = useRef<Interval[]>(intervals);
  const [editingTimerId, setEditingTimerId] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchTimers = async () => {
      try {
        const response = await api.get("/timers");
        setSavedTimers(
          response.data.reduce(
            (acc: Record<string, Timer>, timer: Timer) => ({ ...acc, [timer.id]: timer }),
            {}
          )
        );
      } catch (error) {
        console.error("Error fetching timers:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchTimers();
  }, []);

  useEffect(() => {
    intervalsRef.current = intervals;
  }, [intervals]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = event.target;
    setNewInterval((prevInterval) => ({
      ...prevInterval,
      [name]: name === "exercise_number" ? parseInt(value, 10) || "" : value,
    }));
  };

  const handleIntervalInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    index: number
  ) => {
    const { name, value } = event.target;
    setIntervals((prevIntervals) => {
      const updatedIntervals = [...prevIntervals];
      const key = name as keyof Interval;

      if (key === "exercise_number") {
        updatedIntervals[index][key] = parseInt(value, 10) || 0;
      } else if (key === "duration") {
        updatedIntervals[index][key] = parseInt(value, 10);
      } else {
        updatedIntervals[index][key] = value as string;
      }

      return updatedIntervals;
    });
  };

  const addInterval = () => {
    if (newInterval.duration > 0) {
      setIntervals([
        ...intervals,
        {
          duration: newInterval.duration,
          exercise_number: newInterval.exercise_number,
          display_type: newInterval.display_type,
          intervalType: newInterval.intervalType,
        },
      ]);
      setNewInterval({
        duration: 30,
        intervalType: "main",
        exercise_number: "",
        display_type: "",
      });
    } else {
      alert("Please fill in all fields and ensure the duration is positive.");
    }
  };

  const handleDeleteInterval = (index: number) => {
    const updatedIntervals = [...intervals];
    updatedIntervals.splice(index, 1);
    setIntervals(updatedIntervals);
  };

  const saveTimer = async () => {
    setErrorMessage(""); // Clear previous error messages

    if (timerName.trim() === "") {
      setErrorMessage("Timer name is required.");
      return;
    }

    // Check if bsportId is empty or not a valid number
    if (!bsportId || parseInt(bsportId, 10) <= 0) {
      setErrorMessage("Bsport ID is required.");
      return;
    }

    if (intervals.length === 0) {
      setErrorMessage("At least one interval is required.");
      return;
    }

    const formattedIntervals = intervals.map((interval) => ({
      ...interval,
      duration: parseInt(interval.duration.toString(), 10),
      exercise_number: interval.exercise_number || null,
    }));

    const timerData = {
      name: timerName,
      intervals: formattedIntervals,
      bsport_id: bsportId, // Include bsport_id
    };

    try {
      let response;
      if (editingTimerId) {
        response = await api.put(
          `/timers/${editingTimerId}`,
          timerData
        );
      } else {
        response = await api.post("/timers", timerData);
      }

      const updatedTimersResponse = await api.get("/timers");
      setSavedTimers(
        updatedTimersResponse.data.reduce(
          (acc: Record<string, Timer>, timer: Timer) => ({ ...acc, [timer.id]: timer }),
          {}
        )
      );

      setIntervals([]);
      setTimerName("");
      setBsportId("");
      setEditingTimerId(null);

      return response;
    } catch (error) {
      console.error("Error saving timer:", error);
    }
  };

  const handleEditTimer = async (id: string) => {
    try {
      const response = await api.get(`/timers/${id}`);
      setIntervals(response.data.intervals);
      setTimerName(response.data.name);
      setBsportId(response.data.bsport_id || "");
      setEditingTimerId(id);
    } catch (error) {
      console.error("Error fetching timer:", error);
    }
  };

  const handlePlayTimer = async (id: string) => {
    try {
      const response = await api.get(`/timers/${id}`);
      const url = `${window.location.origin}/player?timerId=${id}`;
      sessionStorage.setItem('timerData', JSON.stringify(response.data));
      window.open(url, "_blank");
    } catch (error) {
      console.error("Error fetching timer for playing:", error);
    }
  };

  const handleClearForm = () => {
    setIntervals([]);
    setTimerName("");
    setNewInterval({
      duration: 30,
      intervalType: "main",
      exercise_number: "",
      display_type: "",
    });
  };

  const handleDeleteTimer = async (id: string) => {
    try {
      await api.delete(`/timers/${id}`);

      const response = await api.get("/timers");
      setSavedTimers(
        response.data.reduce(
          (acc: Record<string, Timer>, timer: Timer) => ({ ...acc, [timer.id]: timer }),
          {}
        )
      );
    } catch (error) {
      console.error("Error deleting timer:", error);
    }
  };

  const handleDuplicateTimer = async (timerId: string) => {
    const timerData = savedTimers[timerId];

    if (!timerData || timerData.intervals.length === 0) {
      alert("Cannot duplicate a timer with no intervals.");
      return;
    }

    try {
      const response = await api.post("/timers", {
        name: `${timerData.name} Copy`,
        intervals: timerData.intervals.map((interval) => ({
          ...interval,
        })),
        bsport_id: null, // Set bsport_id to null for the duplicate
      });

      const updatedTimersResponse = await api.get("/timers");
      setSavedTimers(
        updatedTimersResponse.data.reduce(
          (acc: Record<string, Timer>, timer: Timer) => ({ ...acc, [timer.id]: timer }),
          {}
        )
      );

      setEditingTimerId(response.data.id);
      setTimerName(response.data.name);
      setIntervals(response.data.intervals);
    } catch (error) {
      console.error("Error duplicating timer:", error);
    }
  };

  const formatDuration = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${String(minutes).padStart(2, "0")}:${String(
      remainingSeconds
    ).padStart(2, "0")}`;
  };

  const moveInterval = (index: number, direction: "up" | "down") => {
    setIntervals((prevIntervals) => {
      const newIntervals = [...prevIntervals];
      if (direction === "up" && index > 0) {
        const [removed] = newIntervals.splice(index, 1);
        newIntervals.splice(index - 1, 0, removed);
      } else if (direction === "down" && index < newIntervals.length - 1) {
        const [removed] = newIntervals.splice(index, 1);
        newIntervals.splice(index + 1, 0, removed);
      }
      return newIntervals;
    });
  };

  const getBackgroundColorForInterval = (intervalType: string) => {
    if (intervalType.includes("main") || intervalType.includes("challenge")) {
      return "#FCACA8";
    } else if (
      intervalType.includes("break") ||
      intervalType.includes("switch") ||
      intervalType.includes("pause") ||
      intervalType.includes("stretching") ||
      intervalType.includes("breathing") ||
      intervalType.includes("celebration") ||
      intervalType.includes("station")
    ) {
      return "#A9CAFD";
    } else if (intervalType.includes("warmup")) {
      return "#F9EBA6";
    } else {
      return "#d3d3d3";
    }
  };

  const refreshTimers = async () => {
    try {
      await api.post("/bsport/refresh_timers");
      // Re-fetch timers after refreshing
      const response = await api.get("/timers");
      setSavedTimers(
        response.data.reduce(
          (acc: Record<string, Timer>, timer: Timer) => ({ ...acc, [timer.id]: timer }),
          {}
        )
      );
    } catch (error) {
      console.error("Error refreshing timers:", error);
    }
  };

  return (
    <Layout>
      <div className="timer-page-container">
        <div className="left-column">
          <h2>Saved Timers</h2>
          <button onClick={refreshTimers}>Refresh Timers</button>
          <ul className="timer-list">
            {Object.entries(savedTimers).map(([timerId, timerData]) => (
              <li key={timerId} className="timer-list-item">
                <span>{timerData.name}</span>
                {timerData.total_duration && (
                  <span className="total-duration">
                    ({formatDuration(timerData.total_duration)})
                  </span>
                )}
                <div className="timer-list-buttons">
                  <button onClick={() => handleEditTimer(timerId)}>
                    <FaPencilAlt />
                  </button>
                  <button onClick={() => handlePlayTimer(timerId)}>
                    <FaPlay />
                  </button>
                  <button onClick={() => handleDeleteTimer(timerId)}>
                    <FaTrashAlt />
                  </button>
                  {editingTimerId && (
                    <button
                      onClick={() => handleDuplicateTimer(timerId)}
                      disabled={isLoading}
                      title="Duplicate Timer"
                    >
                      <FaCopy />
                    </button>
                  )}
                </div>
              </li>
            ))}
          </ul>
        </div>

        <div className="right-column">
          <div className="admin-form-container">
            <h2>
              {timerName in savedTimers ? "Edit Timer" : "Create Your Timer"}
            </h2>

            <div>
              <label htmlFor="timerName">Timer Name:</label>
              <input
                type="text"
                id="timerName"
                value={timerName}
                onChange={(e) => setTimerName(e.target.value)}
              />
            </div>

            <div>
              <label htmlFor="bsportId">Bsport ID:</label> 
              <input
                type="number"
                id="bsportId"
                value={bsportId}
                onChange={(e) => setBsportId(e.target.value)}
              />
            </div>

            {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}


            <h3>Intervals:</h3>
            {isLoading ? (
              <p>Loading...</p>
            ) : (
              <table className="admin-list-table">
                <thead>
                  <tr>
                    <th>Duration</th>
                    <th>Type</th>
                    <th>Exercise Number</th>
                    <th>Display Type</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {intervals && intervals.length > 0 ? (
                    intervals.map((interval, index) => (
                      <tr
                        key={index}
                        style={{
                          backgroundColor: getBackgroundColorForInterval(
                            interval.intervalType
                          ),
                        }}
                      >
                        <td>
                          <input
                            type="number"
                            value={interval.duration}
                            onChange={(e) =>
                              handleIntervalInputChange(e, index)
                            }
                            name="duration"
                          />
                        </td>
                        <td>
                          <select
                            value={interval.intervalType}
                            onChange={(e) =>
                              handleIntervalInputChange(e, index)
                            }
                            name="intervalType"
                          >
                            {intervalTypeOptions.map((option) => (
                              <option key={option} value={option}>
                                {option}
                              </option>
                            ))}
                          </select>
                        </td>
                        {interval.intervalType.includes("main") ||
                        interval.intervalType.includes("warmup") ? (
                          <td>
                            <input
                              type="number"
                              value={interval.exercise_number}
                              onChange={(e) =>
                                handleIntervalInputChange(e, index)
                              }
                              name="exercise_number"
                            />
                          </td>
                        ) : (
                          <td>
                            <p>-</p>
                          </td>
                        )}

                        {typeof interval.exercise_number === 'number' && interval.exercise_number > 0 ? (
                          <td>
                            <select
                              value={interval.display_type}
                              onChange={(e) =>
                                handleIntervalInputChange(e, index)
                              }
                              name="display_type"
                            >
                              {displayTypeOptions.map((option) => (
                                <option key={option} value={option}>
                                  {option}
                                </option>
                              ))}
                            </select>
                          </td>
                        ) : (
                          <td>
                            <p>-</p>
                          </td>
                        )}

                        <td>
                          <div className="actions">
                            <button
                              onClick={() => moveInterval(index, "up")}
                              disabled={index === 0}
                              className="button"
                            >
                              <FaArrowUp title="Move Up" />
                            </button>
                            <button
                              onClick={() => moveInterval(index, "down")}
                              disabled={index === intervals.length - 1}
                              className="button"
                            >
                              <FaArrowDown title="Move Down" />
                            </button>
                            <button onClick={() => handleDeleteInterval(index)}>
                              <FaTrashAlt />
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={4}>No intervals yet. Add some!</td>
                    </tr>
                  )}
                </tbody>
              </table>
            )}
            <div>
              <h4>Add Interval</h4>
            </div>
            <table className="admin-list-table">
              <thead>
                <tr>
                  <th>Duration</th>
                  <th>Type</th>
                  <th>Exercise Number</th>
                  <th>Display Type</th>

                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <input
                      type="number"
                      name="duration"
                      value={newInterval.duration}
                      onChange={handleInputChange}
                    />
                  </td>
                  <td>
                    <select
                      value={newInterval.intervalType}
                      onChange={handleInputChange}
                      name="intervalType"
                    >
                      {intervalTypeOptions.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>
                  </td>
                  {newInterval.intervalType.includes("main") ||
                  newInterval.intervalType.includes("warmup") ? (
                    <td>
                      <input
                        type="number"
                        value={newInterval.exercise_number}
                        onChange={handleInputChange}
                        name="exercise_number"
                      />
                    </td>
                  ) : (
                    <td>
                      <p>-</p>
                    </td>
                  )}

                  {typeof newInterval.exercise_number === 'number' && newInterval.exercise_number > 0 ? (
                    <td>
                      <select
                        value={newInterval.display_type}
                        onChange={handleInputChange}
                        name="display_type"
                      >
                        {displayTypeOptions.map((option) => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>
                    </td>
                  ) : (
                    <td>
                      <p>-</p>
                    </td>
                  )}

                  <td>
                    <div className="actions">
                      <button onClick={addInterval} className="add-button">
                        Add
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <div>
              <button onClick={saveTimer}>
                {editingTimerId ? "Update Timer" : "Save New Timer"}
              </button>
            </div>
            <div>
              <button onClick={handleClearForm}>Clear</button>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default TimerPage;
