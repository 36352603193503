import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ProtectedRoute from './ProtectedRoute';
import { AuthProvider } from './AuthContext';
import LoginSignup from "./LoginSignup";
import HomePage from "./HomePage";
import Player from "./Player";
import TimerPage from "./TimerPage";
import WorkoutPage from "./WorkoutPage";
import WorkoutCreate from "./WorkoutCreate";
import WorkoutEdit from "./WorkoutEdit";
import WorkoutDetails from "./WorkoutDetails";
import WorkoutPlayer from "./WorkoutPlayer";
import LocationPage from "./LocationPage";
import LocationCreate from "./LocationCreate";
import LocationEdit from "./LocationEdit";
import LocationDetails from "./LocationDetails";
import CustomerPage from "./CustomerPage";
import CustomerCreate from "./CustomerCreate";
import CustomerEdit from "./CustomerEdit";
import CustomerDetails from "./CustomerDetails";
import ExercisePage from "./ExercisePage";
import ExerciseCreateEdit from "./ExerciseCreateEdit";
import BookingPage from "./BookingPage";
import LocationWorkouts from './LocationWorkouts';
import LocationCoachView from './LocationCoachView';
import WodPage from './WodPage';
import WodDetails from './WodDetails';
import LocationCustomerView from './LocationCustomerView';
import UnauthorizedPage from "./UnauthorizedPage"; // Import the new component
import WorkoutWeightDisplay from "./WorkoutWeightDisplay";

const App: React.FC = () => {
  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <AuthProvider>
        <Routes>
          {/* Login is public to anyone */}
          <Route path="/login" element={<LoginSignup />} />
          
          {/* Homepage is public but requires auth */}
          <Route path="/" element={<ProtectedRoute><HomePage /></ProtectedRoute>} />
          {/* Protected routes for admins */}
          <Route path="/player" element={<ProtectedRoute allowedRoles={['admin']}><Player /></ProtectedRoute>} />
          <Route path="/timers" element={<ProtectedRoute allowedRoles={['admin']}><TimerPage /></ProtectedRoute>} />
          <Route path="/workouts" element={<ProtectedRoute allowedRoles={['admin']}><WorkoutPage /></ProtectedRoute>} />
          <Route path="/workout/create" element={<ProtectedRoute allowedRoles={['admin']}><WorkoutCreate /></ProtectedRoute>} />
          <Route path="/workout/:workoutId" element={<ProtectedRoute allowedRoles={['admin']}><WorkoutDetails /></ProtectedRoute>} />
          <Route path="/workout/:workoutId/edit" element={<ProtectedRoute allowedRoles={['admin']}><WorkoutEdit /></ProtectedRoute>} />
          <Route path="/location/create" element={<ProtectedRoute allowedRoles={['admin']}><LocationCreate /></ProtectedRoute>} />
          <Route path="/location/:locationId/edit" element={<ProtectedRoute allowedRoles={['admin']}><LocationEdit /></ProtectedRoute>} />
          <Route path="/exercises" element={<ProtectedRoute allowedRoles={['admin']}><ExercisePage /></ProtectedRoute>} />
          <Route path="/exercises/createedit" element={<ProtectedRoute allowedRoles={['admin']}><ExerciseCreateEdit /></ProtectedRoute>} />
          <Route path="/bookings" element={<ProtectedRoute allowedRoles={['admin']}><BookingPage /></ProtectedRoute>} />
          <Route path="/customer/:customerId" element={<ProtectedRoute allowedRoles={['admin']}><CustomerDetails /></ProtectedRoute>} />
          <Route path="/customer/:customerId/edit" element={<ProtectedRoute allowedRoles={['admin']}><CustomerEdit /></ProtectedRoute>} />
          <Route path="/customers" element={<ProtectedRoute allowedRoles={['admin']}><CustomerPage /></ProtectedRoute>} />
          <Route path="/customer/create" element={<ProtectedRoute allowedRoles={['admin']}><CustomerCreate /></ProtectedRoute>} />
          <Route path="/wods" element={<ProtectedRoute allowedRoles={['admin']}><WodPage /></ProtectedRoute>} />
          <Route path="/wod/:id" element={<ProtectedRoute allowedRoles={['admin']}><WodDetails /></ProtectedRoute>} />
          
          {/* Protected routes for admin AND trainer */}
          <Route path="/locations" element={<ProtectedRoute allowedRoles={['admin', 'trainer']}><LocationPage /></ProtectedRoute>} />
          <Route path="/location/:locationId" element={<ProtectedRoute allowedRoles={['admin', 'trainer']}><LocationDetails /></ProtectedRoute>} />
          <Route path="/location/:locationId/play" element={<ProtectedRoute allowedRoles={['admin', 'trainer']}><WorkoutPlayer /></ProtectedRoute>} />
          <Route path="/location/:locationId/weights/:displayNumber" element={<ProtectedRoute allowedRoles={['admin', 'trainer']}><WorkoutWeightDisplay /></ProtectedRoute>} />
          <Route path="/location/:locationId/workouts" element={<ProtectedRoute allowedRoles={['admin', 'trainer']}><LocationWorkouts /></ProtectedRoute>} />
          <Route path="/location/:locationId/workout/:workoutId/coach" element={<ProtectedRoute allowedRoles={['admin', 'trainer']}><LocationCoachView /></ProtectedRoute>} />
          <Route path="/location/:locationId/customers/:displayNumber" element={<LocationCustomerView />} />
          
          {/* Add the Unauthorized route */}
          <Route path="/unauthorized" element={<UnauthorizedPage />} />
        </Routes>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
