import React, { useState, useEffect, useRef } from 'react';
import api from './axiosConfig';
import { useParams } from 'react-router-dom';
import './LocationTablet.css';
import LocationWeightAdjustment from './LocationWeightAdjustment';
import LocationSpotSelection from './LocationSpotSelection';
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from '@mui/icons-material/Check';
import { getNextWeight } from './utils/weightUtils';
import TwoChoiceModal from './TwoChoiceModal';
import { toast } from 'react-toastify';

interface Booking {
  id: string;
  customer_id: string;
  customer_first_name: string;
  customer_last_name: string;
  customer_profile_image_url: string | null;
  requires_signup: boolean;
  email?: string;
  workout_id: string;
  spot: string | null;
  has_attended: boolean;
  canceled_at: string | null;
  workout_number: number;
  language: 'DE' | 'EN' | null;
  corrections_only: boolean;
}

interface Exercise {
  exerciseId: string;
  exerciseName: string;
  exerciseShortName?: string;
  weight: number;
  reps: number;
  equipment: "Leg Press" | "Cable" | "Dumbbell" | "Barbell" | "Smith Machine";
  exerciseNumber: number;
}

interface AllCustomerWeights {
  [bookingId: string]: Exercise[];
}

interface WebSocketMessage {
  type: string;
  locationId: string;
  workoutId?: string;
}

interface MergeModalState {
  isOpen: boolean;
  message: string;
  existingCustomerId?: string;
  existingBsportId?: string;
}

const LocationCustomerView: React.FC = () => {
  const { locationId, displayNumber } = useParams<{ locationId: string; displayNumber?: string }>();
  const [bookings, setBookings] = useState<Booking[]>([]);
  const workoutIdRef = useRef<string | null>(null);
  const [currentView, setCurrentView] = useState<'list' | 'emailEntry' | 'completion' | 'spotSelection' | 'detail' | 'languageSelection'>('list');
  const [selectedBooking, setSelectedBooking] = useState<Booking | null>(null);
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [loading, setLoading] = useState(true);
  const [floorplanUrl, setFloorplanUrl] = useState<string | null>(null);
  const [maxSpots, setMaxSpots] = useState<number>(0);
  const [allCustomerWeights, setAllCustomerWeights] = useState<AllCustomerWeights>({});
  const [ws, setWs] = useState<WebSocket | null>(null);
  const reconnectTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const dataFetchedRef = useRef(false);
  const [mergeModalState, setMergeModalState] = useState<MergeModalState>({
    isOpen: false,
    message: '',
  });


  // Function to fetch bookings and weights
  const fetchBookingsAndWeights = async (workoutId: string) => {
    try {
      const bookingsResponse = await api.get(`/bookings`, {
        params: { workout_id: workoutId },
      });
      setBookings(bookingsResponse.data);
      
      const weightsResponse = await api.get(`/workouts/${workoutId}/weights`);
      const weightsData = weightsResponse.data;

      const groupedWeights: AllCustomerWeights = weightsData.reduce((acc: AllCustomerWeights, item: any) => {
        const { booking_id, exercise_id, exercise_name, exercise_short_name, weight, reps, equipment, exercise_number } = item;
        if (!acc[booking_id]) {
          acc[booking_id] = [];
        }
        acc[booking_id].push({
          exerciseId: exercise_id,
          exerciseName: exercise_name,
          exerciseShortName: exercise_short_name,
          weight: parseFloat(weight),
          reps: parseInt(reps, 10),
          equipment: equipment,
          exerciseNumber: exercise_number,
        });
        return acc;
      }, {});

      setAllCustomerWeights(groupedWeights);
    } catch (error) {
      console.error('Error fetching bookings and weights:', error);
    }
  };
  const fetchData = async () => {
    try {
      const locationResponse = await api.get(`/locations/${locationId}`);
      setFloorplanUrl(locationResponse.data.floorplan_url);
      setMaxSpots(locationResponse.data.max_spots);
      dataFetchedRef.current = true; // Mark data as fetched

    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false); // Set isLoading to false after data is fetched
    }
  };

  useEffect(() => {
    const connectWebSocket = () => {
      const wsUrl =
        process.env.NODE_ENV === 'production'
          ? `wss://${new URL(process.env.REACT_APP_API_URL!).host}`
          : 'ws://localhost:5001';

      const newWs = new WebSocket(wsUrl);

      newWs.onopen = () => {
        console.log('LocationCustomerView connected to WebSocket server');
        newWs.send(JSON.stringify({ type: 'join', locationId }));
      };

      newWs.onmessage = (event) => {
        const data: WebSocketMessage = JSON.parse(event.data);
        if ((data.type === 'refreshTimer' || data.type === 'resetTimer') && data.locationId === locationId && data.workoutId) {
          workoutIdRef.current = data.workoutId;
          fetchData();
          fetchBookingsAndWeights(data.workoutId);
        }
        if (data.type === "timerUpdate" && data.locationId === locationId && data.workoutId) {
          workoutIdRef.current = data.workoutId;
          if (!dataFetchedRef.current) {
            fetchData();
            fetchBookingsAndWeights(data.workoutId);
          }
        }
        
        if (data.type === "bookingUpdate" && data.workoutId === workoutIdRef.current) {
          fetchBookingsAndWeights(data.workoutId);
        }
      };

      newWs.onclose = () => {
        console.log('WebSocket connection closed. Attempting to reconnect...');
        reconnectTimeoutRef.current = setTimeout(connectWebSocket, 5000);
      };

      newWs.onerror = (error) => {
        console.error('WebSocket error:', error);
        newWs.close();
      };

      setWs(newWs);
    };

    connectWebSocket();

    return () => {
      if (ws) {
        ws.close();
      }
      if (reconnectTimeoutRef.current) {
        clearTimeout(reconnectTimeoutRef.current);
      }
    };
  }, [locationId]);

  useEffect(() => {
    if (currentView === 'completion') {
      handleCompletion();
    }
  }, [currentView]);

  const handleCardClick = (booking: Booking) => {
    setSelectedBooking(booking);
    if (booking.requires_signup && !booking.spot) {
      setEmail('');
      setCurrentView('emailEntry');
    } else if (!booking.language) {
      setCurrentView('languageSelection');
    } else if (!booking.spot) {
      setCurrentView('spotSelection');
    } else if (!booking.has_attended) {
      setCurrentView('completion');
    } else {
      setCurrentView('detail');
    }
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmailError('');
    setEmail(event.target.value);
  };
  
  const handleSaveEmail = async () => {
    if (!validateEmail(email)) {
      setEmailError('Please enter a valid email address.');
      return;
    }

    if (selectedBooking) {
      try {
        const response = await api.put(`/customers/${selectedBooking.customer_id}/email`, { email });
        if (response.status === 200) {
          const updatedCustomer = response.data.customer;
          const updatedBookings = bookings.map((booking) =>
            booking.id === selectedBooking.id ? { ...booking, email: updatedCustomer.email, requires_signup: updatedCustomer.requires_signup } : booking
          );
          setBookings(updatedBookings);
          setEmail('');
          if (!selectedBooking.language) {
            setCurrentView('languageSelection');
          } else if (!selectedBooking.spot) {
            setCurrentView('spotSelection');
          } else {
            setCurrentView('completion');
          }
        } else {
          console.error('Failed to update email in the database');
          setEmailError('Failed to update email in the database. Maybe an account with this email already exists.');
        }
      } catch (error: any) {
        console.error('Error updating email:', error);
        
        // Check if this is a duplicate email error
        if (error.response?.status === 409 && error.response?.data?.code === 'EMAIL_ALREADY_EXISTS') {
          // Show merge accounts modal
          setMergeModalState({
            isOpen: true,
            message: 'An account with this email already exists. Do you want to merge accounts?',
            existingCustomerId: error.response.data.existingCustomerId,
            existingBsportId: error.response.data.existingBsportId
          });
        } else {
          setEmailError('Error updating email in the database. Please ask the trainer to check you in reach out to our support.');
        }
      }
    }
  };

  const handleMergeResponse = async (shouldMerge: boolean) => {
    if (!shouldMerge || !selectedBooking || !mergeModalState.existingCustomerId) {
      // User clicked "Cancel" or we're missing required data
      setMergeModalState({ isOpen: false, message: '' });
      return;
    }
    
    try {
      // Initiate the merge process
      await api.post('/customers/merge', {
        sourceCustomerId: mergeModalState.existingCustomerId, // The existing customer with the email
        destinationCustomerId: selectedBooking.customer_id, // The customer we're updating
        email: email // The email we're trying to set
      });
      
      // Close the modal and proceed to the next screen
      setMergeModalState({ isOpen: false, message: '' });
      
      // Move to the next screen without waiting for merge completion
      if (!selectedBooking.language) {
        setCurrentView('languageSelection');
      } else if (!selectedBooking.spot) {
        setCurrentView('spotSelection');
      } else {
        setCurrentView('completion');
      }
      
    } catch (error) {
      console.error('Error initiating account merge:', error);
      setEmailError('Failed to merge accounts. Please try a different email.');
      setMergeModalState({ isOpen: false, message: '' });
    }
  };

  const handleLanguageSelection = async (language: 'DE' | 'EN') => {
    if (selectedBooking) {
      try {
        await api.put(`/customers/${selectedBooking.customer_id}`, { language });
        setBookings(prevBookings =>
          prevBookings.map(booking =>
            booking.id === selectedBooking.id ? { ...booking, language } : booking
          )
        );
        if (!selectedBooking.spot) {
          setCurrentView('spotSelection');
        } else {
          setCurrentView('completion');
        }
      } catch (error) {
        console.error('Error updating language:', error);
      }
    }
  };

  const handleSpotSave = async (selectedSpot: string): Promise<boolean> => {
    if (selectedBooking) {
        try {
            const response = await api.put(`/bookings/${selectedBooking.id}/assign_spot`, { 
                spot: selectedSpot 
            });
            
            // Only proceed if we got a valid response with the updated booking
            if (response?.data?.spot === selectedSpot) {
                setSelectedBooking(prev => prev ? { ...prev, spot: selectedSpot } : null);
                setBookings(prevBookings =>
                    prevBookings.map(booking =>
                        booking.id === selectedBooking.id ? { ...booking, spot: selectedSpot } : booking
                    )
                );
                
                setCurrentView('completion');
                return true;
            }
            await fetchBookingsAndWeights(workoutIdRef.current!);
            return false;
            
        } catch (error: any) {
            if (error.response?.data?.code === 'SPOT_TAKEN') {
                await fetchBookingsAndWeights(workoutIdRef.current!);
                return false;
            }
            console.error('Error assigning spot:', error);
            return false;
        }
    }
    return false;
  };

  const handleCompletion = async () => {
    if (selectedBooking && !selectedBooking.has_attended) {
      try {
        await api.put(`/bookings/${selectedBooking.id}/attendance`, {
          attendance: true,
        });
        setSelectedBooking({ ...selectedBooking, has_attended: true });
        setBookings(prevBookings =>
          prevBookings.map(booking =>
            booking.id === selectedBooking.id ? { ...booking, has_attended: true } : booking
          )
        );
      } catch (error) {
        console.error("Error updating booking attendance:", error);
      }
    }
    setTimeout(() => {
      setCurrentView('list');
    }, 2500);
  };

  const bookedSpots = bookings.map(booking => booking.spot).filter(spot => spot !== null) as string[];

  const validateEmail = (email: string) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const adjustWeight = async (exerciseId: string, adjustment: number) => {
    if (!selectedBooking) return;

    const customerWeights = allCustomerWeights[selectedBooking.id];
    const exercise = customerWeights.find(ex => ex.exerciseId === exerciseId);

    if (!exercise) {
      console.error("Exercise not found");
      return;
    }

    const newWeight = getNextWeight(exercise.equipment, exercise.weight, adjustment);

    // Determine which RM field to update based on reps
    const rmField = `weight_${exercise.reps}rm`;

    try {
      await api.post(`/customer_strength_profiles`, {
        customer_id: selectedBooking.customer_id,
        exercise_id: exerciseId,
        [rmField]: newWeight, // Use computed property name
      });

      setAllCustomerWeights((prevWeights) => ({
        ...prevWeights,
        [selectedBooking.id]: prevWeights[selectedBooking.id].map(ex =>
          ex.exerciseId === exerciseId ? { ...ex, weight: newWeight } : ex
        ),
      }));

      // Send booking update notification over WebSocket
      if (ws && ws.readyState === WebSocket.OPEN) {
        ws.send(JSON.stringify({
          type: "bookingUpdate",
          workoutId: workoutIdRef.current,
        }));
      }
    } catch (error) {
      console.error("Error updating strength profile:", error);
    }
  };

  const handleCorrectionsToggle = async () => {
    if (selectedBooking) {
      const newValue = !selectedBooking.corrections_only;
      try {
        await api.put(`/bookings/${selectedBooking.id}/corrections`, {
          correctionsOnly: newValue
        });
        
        // Just update local state - the WebSocket notification is handled by the server
        setSelectedBooking(prev => prev ? { ...prev, corrections_only: newValue } : null);
        setBookings(prevBookings =>
          prevBookings.map(booking =>
            booking.id === selectedBooking.id ? { ...booking, corrections_only: newValue } : booking
          )
        );
        
      } catch (error) {
        console.error("Error updating corrections mode:", error);
        toast.error("Failed to update corrections mode");
      }
    }
  };

  return (
    <div className="location-tablet">
      {currentView === 'list' && (
        <>
          <div className="flex-vertical gap-xs">
            <div className="top-info-buttons"></div>
            <div className="flex-vertical">
              <h2>Welcome,</h2>
              <h1>please sign in below</h1>
            </div>
          </div>
          {loading ? (
            <p>Loading...</p>
          ) : bookings.length === 0 ? (
            <p>No customers to sign up. Ask the coach to refresh if needed.</p>
          ) : (
            <div className="card-list">
              {bookings
                .slice() // Create a shallow copy to avoid mutating the original state
                .sort((a, b) => a.customer_first_name.localeCompare(b.customer_first_name))
                .map((booking) => (
                  <div
                    key={booking.id}
                    className={`card-s ${booking.spot && booking.has_attended ? 'outlined' : ''
                    }`}
                    onClick={() => handleCardClick(booking)}
                  >
                    {booking.customer_profile_image_url ? (
                      <img
                        src={booking.customer_profile_image_url}
                        alt="Customer Profile"
                        className="profile-avatar-s"
                      />
                    ) : (
                      <div className="profile-placeholder-s">
                        {booking.customer_first_name.charAt(0)}
                      </div>
                    )}

                    <div className="card-s-name-element">
                      {booking.customer_first_name} {booking.customer_last_name.slice(0, 2)}.
                    </div>
                    <div className="card-s-center-element">{booking.spot || ''}</div>
                    <div className="card-s-right-element">
                      {booking.requires_signup && !booking.spot ? 'Sign in' :
                        !booking.spot ? 'Pick spot' :
                          !booking.has_attended ? 'Check in' : 'Change weight'}
                    </div>
                  </div>
                ))}
            </div>
          )}
        </>
      )}

      {currentView === 'emailEntry' && selectedBooking && (
        <>
          <div className="top-info-buttons">
            <button className="button-m secondary square" onClick={() => setCurrentView('list')}>
              <CloseIcon className="icon-l" />
            </button>
          </div>
          <div className="flex-vertical">
            <h2>Welcome {selectedBooking.customer_first_name},</h2>
            <h1>just one step left!</h1>
          </div>
          <div className="flex-horizontal full-width">
            <input
              type="email"
              className="input-field"
              placeholder="Email"
              value={email}
              onChange={handleEmailChange}
            />
          </div>
          {emailError && <p style={{ color: 'red' }}>{emailError}</p>}
          <p>I hereby permit Lifted Fitness GmbH to use my email address to contact me in regards to my workout results and other promotions.</p>
          <button className="button-m special" onClick={handleSaveEmail}>
            Save
          </button>
        </>
      )}

      {currentView === 'languageSelection' && selectedBooking && (
        <>
          <div className="top-info-buttons">
            <button className="button-m secondary square" onClick={() => setCurrentView('list')}>
              <CloseIcon className="icon-l" />
            </button>
          </div>
          <div className="flex-vertical">
            <h2>Welcome {selectedBooking.customer_first_name},</h2>
            <h1>how should our trainers talk to you?</h1>
          </div>
          <div className="flex-horizontal gap-m justify-center">
            <button 
              className="button-circle large"
              onClick={() => handleLanguageSelection('DE')}
            >
              🇩🇪
            </button>
            <button 
              className="button-circle large"
              onClick={() => handleLanguageSelection('EN')}
            >
              🇬🇧
            </button>
          </div>
        </>
      )}

      {currentView === 'spotSelection' && selectedBooking && floorplanUrl && maxSpots > 0 && (
        <LocationSpotSelection
          floorplanUrl={floorplanUrl}
          maxSpots={maxSpots}
          bookedSpots={bookedSpots}
          onClose={() => setCurrentView('list')}
          onSave={handleSpotSave}
          greeting={`Hey ${selectedBooking.customer_first_name},`}
          displayNumber={displayNumber}
        />
      )}

      {currentView === 'completion' && (
        <>
          <div className="top-info-buttons"></div>
          <div className="flex-vertical">
            <h2>Done,</h2>
            <h1>enjoy your workout!</h1>
          </div>
          <div className="space-between-box">
            <div className="circled-icon">
              <CheckIcon style={{ fontSize: 72 }} />
            </div>
          </div>
        </>
      )}

      {currentView === 'detail' && selectedBooking && (
        <LocationWeightAdjustment
          selectedBooking={selectedBooking}
          allCustomerWeights={allCustomerWeights}
          toggleDetailView={() => setCurrentView('list')}
          handleCheckIn={() => {}}
          adjustWeight={adjustWeight}
          onSpotButtonClick={() => {}}
          isCoachView={false}
          handleCorrectionsToggle={handleCorrectionsToggle}
          displayNumber={displayNumber}
        />
      )}

      <TwoChoiceModal
        isOpen={mergeModalState.isOpen}
        onResponse={handleMergeResponse}
        message={mergeModalState.message}
        aText="Yes, merge"
        bText="Cancel"
      />
    </div>
  );
}

export default LocationCustomerView;