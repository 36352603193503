import React, { useState, useEffect } from 'react';
import CloseIcon from "@mui/icons-material/Close";
import api from './axiosConfig';

interface Playlist {
  id: string;
  name: string;
  description: string;
  display?: {
    image?: {
      url: string;
    };
  };
}

interface MusicSelectorProps {
  onClose: () => void;
  workoutId: string;
  workoutData: {
    start_datetime: string;
    timer_id: string;
  };
  onPlaylistChange?: () => void;
}

const MusicSelector: React.FC<MusicSelectorProps> = ({ onClose, workoutId, workoutData, onPlaylistChange }) => {
  const [playlists, setPlaylists] = useState<Playlist[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchPlaylists = async () => {
      try {
        const response = await api.get('/playlists');
        const filteredPlaylists = response.data.filter(
          (playlist: Playlist) => !playlist.name.includes('[Automated]')
        );
        setPlaylists(filteredPlaylists);
      } catch (error) {
        console.error('Error fetching playlists:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchPlaylists();
  }, []);

  const handlePlaylistSelect = async (playlistId: string, shuffle: boolean) => {
    try {
      await api.put(`/workouts/${workoutId}`, {
        start_datetime: workoutData.start_datetime,
        timer_id: workoutData.timer_id,
        playlist_id: playlistId,
        shuffle
      });
      onPlaylistChange?.();
      onClose();
    } catch (error) {
      console.error('Error updating workout playlist:', error);
    }
  };

  return (
    <div className="music-selector">
      <div className="top-info-buttons">
        <button className="button-m secondary square" onClick={onClose}>
          <CloseIcon className="icon-l" />
        </button>
      </div>
      
      {loading ? (
        <div>Loading playlists...</div>
      ) : (
        <div className="playlist-list">
          {playlists.map(playlist => (
            <div key={playlist.id} className="playlist-item">
              {playlist.display?.image?.url && (
                <div className="playlist-image">
                  <img src={playlist.display.image.url} alt={playlist.name} />
                </div>
              )}
              <div className="playlist-info">
                <h3>{playlist.name}</h3>
                <p>{playlist.description}</p>
                <div className="playlist-buttons">
                  <button 
                    className="button-s secondary"
                    onClick={() => handlePlaylistSelect(playlist.id, false)}
                  >
                    Play in order
                  </button>
                  <button 
                    className="button-s"
                    onClick={() => handlePlaylistSelect(playlist.id, true)}
                  >
                    Shuffle
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default MusicSelector; 