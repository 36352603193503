import React, { useEffect, useState } from 'react';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CheckIcon from "@mui/icons-material/Check";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import { calculateWeightDifference } from './utils/weightUtils';
import api from './axiosConfig';
import { toast } from 'react-toastify';

interface Exercise {
  exerciseId: string;
  exerciseName: string;
  exerciseShortName?: string;
  weight: number;
  equipment: "Leg Press" | "Cable" | "Dumbbell" | "Barbell" | "Smith Machine";
  exerciseNumber: number;
}

interface Booking {
  customer_first_name: string;
  customer_last_name: string;
  customer_profile_image_url: string | null;
  has_attended: boolean;
  spot: string | null;
  id: string;
  customer_id: string;
  requires_signup: boolean;
  workout_number: number;
  language: 'DE' | 'EN' | null;
  corrections_only: boolean;
}

interface CustomerDetailViewProps {
  selectedBooking: Booking;
  allCustomerWeights: { [bookingId: string]: Exercise[] };
  toggleDetailView: (booking: Booking | null) => void;
  handleCheckIn: () => void;
  adjustWeight: (exerciseId: string, adjustment: number) => void;
  onSpotButtonClick: () => void;
  displayNumber?: string;
  isCoachView?: boolean;
  handleCorrectionsToggle?: () => void;
}

const CustomerDetailView: React.FC<CustomerDetailViewProps> = ({
  selectedBooking,
  allCustomerWeights,
  toggleDetailView,
  handleCheckIn,
  adjustWeight,
  onSpotButtonClick,
  displayNumber,
  isCoachView = false,
  handleCorrectionsToggle,
}) => {
  
  const [inactivityTimeout, setInactivityTimeout] = useState<NodeJS.Timeout | null>(null);
  const [isNameSwitching, setIsNameSwitching] = useState<boolean>(false);
  const [isLanguageSwitching, setIsLanguageSwitching] = useState<boolean>(false);

  // Function to reset inactivity timer
  const resetInactivityTimer = () => {
    if (inactivityTimeout) {
      clearTimeout(inactivityTimeout);
    }
    setInactivityTimeout(setTimeout(() => {
      toggleDetailView(null); // Return to list view after 10 seconds of inactivity
    }, 10000)); // 10 seconds
  };

  // Add new function to handle manual back button click
  const handleBackClick = () => {
    if (inactivityTimeout) {
      clearTimeout(inactivityTimeout);
    }
    toggleDetailView(null);
  };

  // Add function to handle name switch
  const handleNameSwitch = async () => {
    if (!selectedBooking || isNameSwitching) return;
    
    try {
      setIsNameSwitching(true);
      resetInactivityTimer(); // Reset timer on button click
      
      const response = await api.post(`/bsport/name-switch/${selectedBooking.customer_id}`);
      
      // Update the booking in the UI
      const updatedBooking = {
        ...selectedBooking,
        customer_first_name: response.data.newFirstName,
        customer_last_name: response.data.newLastName
      };
      
      toast.success('Names switched successfully!');
      toggleDetailView(updatedBooking);
    } catch (error) {
      console.error('Error switching names:', error);
      toast.error('Failed to switch names. Please try again.');
    } finally {
      setIsNameSwitching(false);
    }
  };

  // Add function to handle language switch
  const handleLanguageSwitch = async () => {
    if (!selectedBooking || isLanguageSwitching) return;
    
    try {
      setIsLanguageSwitching(true);
      resetInactivityTimer();
      
      const newLanguage = selectedBooking.language === 'DE' ? 'EN' : 'DE';
      
      await api.put(`/customers/${selectedBooking.customer_id}`, {
        language: newLanguage
      });
      
      // Update the selectedBooking directly
      selectedBooking.language = newLanguage as 'DE' | 'EN';
      
      toast.success(`Language switched to ${newLanguage === 'DE' ? '🇩🇪' : '🇬🇧'}`);
    } catch (error) {
      console.error('Error switching language:', error);
      toast.error('Failed to switch language. Please try again.');
    } finally {
      setIsLanguageSwitching(false);
    }
  };

  useEffect(() => {
    // Cleanup function to clear the timeout on unmount
    return () => {
      if (inactivityTimeout) {
        clearTimeout(inactivityTimeout);
      }
    };
  }, [inactivityTimeout]);

  const handleWeightChange = (exerciseId: string, adjustment: number) => {
    adjustWeight(exerciseId, adjustment);
    resetInactivityTimer(); // Reset timer on weight change
  };

  return (
    <>
      <div className="flex-vertical gap-xs">
        <button className="button-m secondary square top-left" onClick={handleBackClick}>
          <ArrowBackIcon className="icon-m" />
        </button>
        <div className="flex-vertical centered gap-s">
          {selectedBooking.customer_profile_image_url ? (
            <img
              src={selectedBooking.customer_profile_image_url}
              alt="Profile"
              className="profile-avatar-xl"
            />
          ) : (
            <div className="profile-avatar-xl">
              {selectedBooking.customer_first_name.charAt(0)}
              {selectedBooking.customer_last_name.charAt(0)}
            </div>
          )}
          <h1>
            {selectedBooking.customer_first_name} {selectedBooking.customer_last_name.slice(0, 2)}.
          </h1>
        </div>
      </div>
      
      {/* Admin buttons - shown in coach view */}
      {isCoachView && (
        <div className="flex-vertical gap-s">
          <div className="flex-horizontal space-around">
            <button
              className={`button-l ${selectedBooking.has_attended ? "active" : ""}`}
              onClick={handleCheckIn}
            >
              {selectedBooking.has_attended ? (
                <>
                  <CheckIcon className="icon-m" />
                  Checked In
                </>
              ) : (
                "Check In"
              )}
            </button>
            <button
              className={`button-l ${selectedBooking.spot ? "active" : ""}`}
              onClick={onSpotButtonClick}
            >
              {selectedBooking.spot ? `Spot ${selectedBooking.spot}` : "Pick Spot"}
            </button>
          </div>
          
          <div className="flex-horizontal space-around">
            <button
              className="button-l secondary"
              onClick={handleNameSwitch}
              disabled={isNameSwitching}
            >
              <SwapHorizIcon className="icon-m" />
              {isNameSwitching ? "Switching..." : "Switch Names"}
            </button>
            <button
              className="button-l secondary"
              onClick={handleLanguageSwitch}
              disabled={isLanguageSwitching}
            >
              {selectedBooking.language && (
                <div>
                  {selectedBooking.language === 'DE' ? '🇩🇪' : '🇬🇧'}
                </div>
              )}
              <div>
                {isLanguageSwitching ? "Switching..." : selectedBooking.language ? "Switch Language" : "Pick Language"}
              </div>
            </button>
          </div>
        </div>
      )}
      
      {/* Corrections toggle - shown in customer view */}
      {!isCoachView && handleCorrectionsToggle && (
        <div className="tablet-toggle-container">
          <span className="tablet-toggle-label">I only want corrections, no motivational pushes today</span>
          <label className="tablet-toggle">
            <input
              type="checkbox"
              checked={selectedBooking.corrections_only}
              onChange={handleCorrectionsToggle}
            />
            <span className="tablet-slider"></span>
          </label>
        </div>
      )}
      
      <div className="exercise-list">
        {selectedBooking && allCustomerWeights[selectedBooking.id] ? (
          allCustomerWeights[selectedBooking.id]
            .sort((a, b) => a.exerciseNumber - b.exerciseNumber)
            .map((exercise) => {
              const diffPlus = calculateWeightDifference(exercise.equipment, exercise.weight, 1);
              const diffMinus = calculateWeightDifference(exercise.equipment, exercise.weight, -1);
              const isHighlighted = displayNumber && exercise.exerciseNumber === parseInt(displayNumber);
              return (
                <div 
                  key={exercise.exerciseId} 
                  className={`flex-horizontal space-between ${isHighlighted ? 'highlighted-exercise' : ''}`}
                >
                  <p className='exercise-name'>
                    {exercise.exerciseNumber}. {exercise.exerciseShortName || exercise.exerciseName}
                  </p>
                  <div className="weight-element">
                    {diffMinus !== null && (
                      <button className="button-m" onClick={() => handleWeightChange(exercise.exerciseId, -1)}>
                        -{Math.abs(diffMinus)} kg
                      </button>
                    )}
                    <span className="weight-value">{exercise.weight} kg</span>
                    {diffPlus !== null && (
                      <button className="button-m" onClick={() => handleWeightChange(exercise.exerciseId, 1)}>
                        +{diffPlus} kg
                      </button>
                    )}
                  </div>
                </div>
              );
            })
        ) : null}
      </div>
    </>
  );
};

export default CustomerDetailView;
