import React, { useState, useEffect } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import api from './axiosConfig';
import moment from "moment";
import RefreshIcon from "@mui/icons-material/Refresh";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import "./LocationTablet.css"; // Create a CSS file for styling

interface Workout {
  id: number;
  coach_profile_image_url?: string;
  coach_first_name: string;
  timer_name: string;
  start_datetime: string;
}

function LocationWorkouts() {
  const { locationId } = useParams<{ locationId: string }>();
  const navigate = useNavigate();
  const location = useLocation();
  const [workouts, setWorkouts] = useState<Workout[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    fetchWorkouts();
  }, [locationId, location.search]);

  const fetchWorkouts = async () => {
    setLoading(true);
    try {
      const queryParams = new URLSearchParams(location.search);
      const date = queryParams.get("date");
      const workoutDate = date || moment().format("YYYY-MM-DD");
      const response = await api.get<Workout[]>(
        `/workouts?location_id=${locationId}&date=${workoutDate}&is_cancelled=false&fields=id,coach_profile_image_url,coach_first_name,start_datetime`
      );
      setWorkouts(response.data);
    } catch (error) {
      console.error("Error fetching workouts:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleRefresh = () => {
    fetchWorkouts();
  };

  const handleCardClick = (workoutId: number) => {
    navigate(`/location/${locationId}/workout/${workoutId}/coach`, {
      state: { from: 'locationWorkouts' }
    });
  };

  return (
    <div className="location-tablet">
      <div className="top-info-buttons">
        <p className="text-s">Refresh if wrong day is shown</p>
        <ArrowForwardIcon className="icon-m" />
        <button className="button-m secondary square" onClick={handleRefresh}>
          <RefreshIcon className="icon-l" />
        </button>
      </div>
      <div className="flex-vertical">
        <div>
          <h2>Hi Coach,</h2>
          <h1>Classes of {moment(new URLSearchParams(location.search).get("date") || new Date()).format("ddd, MMM D")}</h1>
        </div>
      </div>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <div className="card-list">
          {workouts.map((workout) => (
            <div
              key={workout.id}
              className="card-s"
              onClick={() => handleCardClick(workout.id)}
            >
              {workout.coach_profile_image_url ? (
                <img
                  src={workout.coach_profile_image_url}
                  alt="Coach Profile"
                  className="profile-avatar-s"
                />
              ) : (
                <div className="profile-placeholder-s">
                  {workout.coach_first_name.charAt(0)}
                </div>
              )}
              
                <div className="card-s-name-element">{workout.coach_first_name}</div>
                <div className="card-s-center-element">{workout.timer_name}</div>
                <div className="card-s-right-element">
                  {moment(workout.start_datetime).format("HH:mm")}
                </div>
              </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default LocationWorkouts;
